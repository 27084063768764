import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { successMessage } from "../../../../services/swalService";
import { Button, Dropdown, message } from "antd";
import "./HotelsTable.scss";
import { convertPrice } from "../../../../services/currencyService";
import dayjs from "dayjs";
import { isAdminUser } from "../../../../services/authService";
import { ApiReqBaseUrl } from "../../../../assets/GlobalVariables";

const PoliciesCellRender = ({ policies, policyremarks, price }) => {
  const { t } = useTranslation();

  const parsedPolicies = JSON.parse(policies)[0];
  const { fee, from, currency } = parsedPolicies;
  return (
    <div>
      {price ? price : fee}
      {currency === "ILS" ? "₪" : currency}
      {"   "} {t("orders.from")} {from}
    </div>
  );
};

const PassengersNamesRenderer = ({ passengers }) => {
  const parsedPassengers = JSON.parse(passengers);

  return parsedPassengers.map((passenger, index) => {
    const { NamePrefix, GivenName, Surname } = passenger?.PersonDetails?.Name;
    return (
      <div key={GivenName + "-" + index}>
        {NamePrefix} {GivenName} {Surname}
      </div>
    );
  });
};

const RoomsTypeRenderer = ({ rooms }) => {
  return rooms.map((room, index) => {
    const { roomname } = room;
    return (
      <div key={index} className="blue-name-cell">
        {roomname}
      </div>
    );
  });
};

const DatesCellRender = ({ checkIn, checkOut }) => {
  return (
    <div>
      {checkIn} - {checkOut}
    </div>
  );
};

const CollapsedHotelRow = ({ row, cancelOrder, updateOrder } = {}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isRemarkesOpen, setIsRemarkesOpen] = useState(false);
  const [isCancelOrderOpen, setIsCancelOrderOpen] = useState(false);

  const {
    agentName: agentInfo,
    passengers,
    hotelname,
    rooms,
    fullname,
    checkin,
    checkout,
    orderid,
    refundableuntil,
    pricetotal,
    policies,
    policyremarks,
    paid,
    id,
    segments,
    handlingfee,
    handle_fee_option,
    commission,
    paymentinterest,
    creationdate,
    payments,
    sumpaid,
    agentName,
    cell,
  } = row;

  // pasre a date like DD/MM/YYYY to dayjs object
  const refundableuntilDate = dayjs(refundableuntil, "DD/MM/YYYY");
  const isCancelDiabled = dayjs().isAfter(refundableuntilDate);

  const [isRequesting, setIsRequesting] = useState(false);

  const handlePayNow = () => {
    if (id) {
      navigate(`/p/dashboard/payment/${id}`);
    }
  };

  const cancelOrderClick = () => {
    cancelOrder({ orderId: id, segmentId: segments[0] }).then(() =>
      successMessage({
        title: "ההזמנה בוטלה בהצלחה",
        message: "",
        confirmButtonText: "אישור",
      })
    );
    setIsCancelOrderOpen(false);
  };

  const CancelOrderModal = ({ policies }) => {
    return (
      <div className="cancel-order-content">
        <div style={{ height: "200px", overflow: "auto" }}>
          <Typography id="modal-modal-description">
            <div
              className="modal-cancelation-policy-remarks"
              dangerouslySetInnerHTML={{
                __html: policyremarks,
              }}
            />
          </Typography>
        </div>
        <PoliciesCellRender policies={policies} />
        <button className="table-button-cancel-now" onClick={cancelOrderClick}>
          {t("orders.cancelSure")}
        </button>
      </div>
    );
  };

  const getFee = (totalPrice, fee, type) => {
    if (type === "%") {
      return (totalPrice * fee) / 100;
    } else {
      return fee;
    }
  };

  const changeOrderStatus = async ({ orderId, status }) => {
    try {
      const agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;
      const res = await fetch(ApiReqBaseUrl + `/orders/${orderId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status, email: agentEmail }),
      });
      if (res.status === 200) {
        message.success("הסטאטוס שונה בהצלחה");
        const data = await res.json();
        updateOrder(data);
        // set new data
      } else {
        message.error("שגיאה בשינוי סטאטוס");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="collapsed-wrapper">
      <div className="column-wrapper">
        {agentInfo ? (
          <>
            <div className="item-wrapper">
              <div className="item-header">{t("orders.agentName")}</div>
              <div>
                {agentInfo.firstName} {agentInfo.lastName}
              </div>
            </div>
            <div className="item-wrapper">
              <div className="item-header">{t("orders.agentEmail")}</div>
              <div>{agentInfo.email}</div>
            </div>
          </>
        ) : null}
        <div className="item-wrapper">
          <div className="item-header">{t("orders.hotelName")}</div>
          <div className="blue-name-cell">{hotelname}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.roomType")}</div>
          <RoomsTypeRenderer rooms={rooms} />
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.createdAt")}</div>
          <div className="created-at-until-cell">{creationdate}</div>
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">{t("orders.mainContact")}</div>
          <div>{fullname}</div>
          <div>{cell}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.passengersNames")}</div>
          <PassengersNamesRenderer passengers={passengers} />
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.stayDates")}</div>
          <DatesCellRender checkIn={checkin} checkOut={checkout} />
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.orderId")}</div>
          <div>{orderid}</div>
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">{t("orders.toPay")}</div>
          <div>{convertPrice(pricetotal)}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">{t("orders.paid")}</div>
          <div>{convertPrice(sumpaid)}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">{t("orders.leftToPay")}</div>
          <div>{convertPrice(pricetotal - sumpaid)}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">ריבית תשלומים</div>
          <div>{convertPrice(paymentinterest)}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.myCommission")}</div>
          <div className="commisions-cell">{convertPrice(commission)}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.handlingFee")}</div>
          <div className="commisions-cell">
            {handlingfee && handle_fee_option
              ? convertPrice(getFee(pricetotal, handlingfee, handle_fee_option))
              : "אין"}
          </div>
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <button
            className={paid ? "table-button-disabled" : "table-button-pay-now"}
            onClick={handlePayNow}
            disabled={paid}
          >
            {t("orders.payNow")} {">"}
          </button>
        </div>
        <div className="item-wrapper">
          <button
            className={
              isCancelDiabled
                ? "table-button-disabled"
                : "table-button-cancel-now"
            }
            disabled={isCancelDiabled}
            onClick={() => setIsCancelOrderOpen(true)}
          >
            {t("orders.cancelOrder")} {">"}
          </button>
        </div>
        {isAdminUser() && (
          <Dropdown
            menu={{
              items: [
                {
                  key: "cancel",
                  label: "ביטול",
                  onClick: async () => {
                    setIsRequesting(true);
                    await changeOrderStatus({
                      orderId: id,
                      status: "cancelled",
                    });
                    setIsRequesting(false);
                  },
                  disabled: isRequesting,
                },
                {
                  key: "pending",
                  label: "ממתין",
                  onClick: async () => {
                    setIsRequesting(true);
                    await changeOrderStatus({ orderId: id, status: "pending" });
                    setIsRequesting(false);
                  },
                  disabled: isRequesting,
                },
                {
                  key: "paid",
                  label: "שולם",
                  onClick: async () => {
                    setIsRequesting(true);
                    await changeOrderStatus({ orderId: id, status: "paid" });
                    setIsRequesting(false);
                  },
                  disabled: isRequesting,
                },
              ],
            }}
            children={<Button loading={isRequesting}>שנה סטאטוס במערכת</Button>}
          />
        )}
        <div className="item-wrapper">
          <div className="item-header">
            {t("orders.cancelationPolicy")}
            <div className="info-icon" onClick={() => setIsRemarkesOpen(true)}>
              <InfoOutlined fontSize="small" />
            </div>
          </div>
          <PoliciesCellRender
            className="policies-cell"
            policies={policies}
            policyremarks={policyremarks}
            price={pricetotal}
          />
          <Modal
            open={isRemarkesOpen}
            onClose={() => {
              setIsRemarkesOpen(false);
            }}
          >
            <Box className="box-style">
              <div style={{ height: "400px", overflow: "auto" }}>
                <Typography id="modal-modal-description">
                  <div
                    className="modal-cancelation-policy-remarks"
                    dangerouslySetInnerHTML={{
                      __html: policyremarks,
                    }}
                  />
                </Typography>
              </div>
            </Box>
          </Modal>
          <Modal
            open={isCancelOrderOpen}
            onClose={() => {
              setIsCancelOrderOpen(false);
            }}
          >
            <Box className="modal-cancel-order">
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <CancelOrderModal policies={policies} />
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

//fullname

export default CollapsedHotelRow;
