import React from "react";
import isEmpty from "lodash/isEmpty";

// Languages
import { useTranslation } from "react-i18next";

const MoreToPay = ({ additionalTaxes = [] }) => {
  // Translator
  const { t } = useTranslation();

  return (
    <>
      <div className="moreToPay-wrapper">
        <label className="moreToPay-title">
          לתשלום בעת ההגעה (לא כלול במחיר)
        </label>

        <div className="moreToPay-content">
          {!isEmpty(additionalTaxes) ? (
            additionalTaxes.map((taxItem, index) => {
              const { FeeTitle, Value, Currency } = taxItem;
              return (
                <div
                  className="moreToPay-content-item"
                  key={FeeTitle + "-" + index}
                >
                  <label className="moreToPay-content-item-title">
                    {FeeTitle}
                  </label>
                  <label id="price-1" className="moreToPay-content-item-value">
                    {Value}
                    {Currency}
                  </label>
                </div>
              );
            })
          ) : (
            <>{t("paymentagent.noAdditionalTaxes")}</>
          )}
        </div>
      </div>
    </>
  );
};

export default MoreToPay;
