import React from "react";
import { useTranslation } from "react-i18next";

const CardsAmount = ({ amount, setAmount, max = 4 }) => {
  const { t } = useTranslation();

  return (
    <div className="cards-amount-wrapper">
      <label className="splitPayment-card-amount-title">
        {t("paymentagent.setnumcard")}
      </label>
      <select
        style={{ marginRight: 10 }}
        onChange={(e) => setAmount(parseInt(e.target.value))}
        defaultValue={amount}
      >
        {[...Array(max).keys()]
          .map((i) => i + 1)
          .map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
      </select>
    </div>
  );
};

export default CardsAmount;
