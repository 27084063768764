import React from "react";
import {
  FormFields,
  useRegistration,
} from "../../../contexts/RegistrationContext";
import RegistrationBox from "../RegistrationBox";
import "./PackageDetails.scss";
import { TransparentButton } from "../../buttons/Buttons";
import { OrangeRegistrationInput } from "../Inputs";
import { AgentTermsLink } from "../../TermsAndConditionsLink/TermsAndConditionsLink";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

const PackageDetails = ({ missingFields }) => {
  const { formData, setFormKey } = useRegistration();
  const TABLE_KEYS = [
    "מחיר לחודש / שנה",
    "חודש ראשון",
    "הקמת המשתמש",
    "מלונות ממגוון ספקים",
    "חבילות נופש",
    "מאורגנים",
    "טיסות צ'ארטר",
    "הפלגות",
    "קרייזי לאמה",
    "שירותי גראפיקה בהנחה",
    "ניהול קמפיינים",
    "תוספת דמי טיפול",
    "תוספת עמלה",
  ];

  const STARTER_AGENT = [
    "ללא עלות",
    "ללא עלות",
    "ללא עלות",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "לא כלול",
    "לא כלול",
    "לא כלול",
    "לא כלול",
    "לא כלול",
    "(70%) - מסך העמלה",
  ];

  const BASIC_AGENT = [
    `(200₪ / 2000₪) + מע"מ`,
    "ללא עלות",
    "ללא עלות",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "לא כלול",
    "לא כלול",
    "לא כלול",
    "כלול בחבילה",
    "(80%) - מסך העמלה",
  ];

  const PRO_AGENT = [
    `(400₪ / 3990₪) + מע"מ`,
    "ללא עלות",
    "ללא עלות",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "כלול בחבילה",
    "50% הנחה",
    "כלול בחבילה",
    "(90%) - מסך העמלה",
  ];

  function getTable(
    items,
    title,
    titleStyle = {},
    onClick,
    selected,
    disabled
  ) {
    const isFirstExampleTable = title === "";
    const getParamsByIndex = (index) => {
      if (index === 0) {
        return { backgroundColor: "white", color: "rgb(255, 130, 0)" };
      }
      if (index % 2 === 0) {
        return { backgroundColor: "#80808094", color: "white" };
      }
      return { backgroundColor: "rgba(241,241,241,0.58)", color: "black" };
    };
    return (
      <Tooltip title={disabled ? "חבילה לא זמינה" : undefined}>
        <div
          className={classNames("package-table", {
            example: isFirstExampleTable,
            disabled,
          })}
          style={selected ? titleStyle : null}
        >
          <div className="package-table-title" style={titleStyle}>
            {title}
          </div>
          {items.map((item, index) => (
            <div
              className={classNames("package-table-item", {
                example: isFirstExampleTable,
              })}
              style={{ ...getParamsByIndex(index) }}
            >
              {item}
            </div>
          ))}
          <span style={{ margin: 5 }}>
            {onClick ? (
              disabled ? null : (
                <TransparentButton onClick={onClick}>
                  {selected ? "החבילה נבחרה" : "בחירת חבילה"}
                </TransparentButton>
              )
            ) : null}
          </span>
        </div>
      </Tooltip>
    );
  }

  return (
    <RegistrationBox title="בחירת חבילה">
      <div
        className="agent_packages"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="packages"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div className="package">
            {getTable(TABLE_KEYS, "")}
            {getTable(
              STARTER_AGENT,
              "STARTER AGENT",
              { backgroundColor: "#66c44e" },
              () => setFormKey(FormFields.ADDITIONAL_INFO.PACKAGE, "STARTER"),
              formData["package"] === "STARTER"
            )}
          </div>
          <div className="package">
            {getTable(TABLE_KEYS, "")}
            {getTable(
              BASIC_AGENT,
              "BASIC AGENT",
              { backgroundColor: "#4e89be" },
              () => setFormKey(FormFields.ADDITIONAL_INFO.PACKAGE, "BASIC"),
              formData["package"] === "BASIC"
            )}
          </div>
          <div className="package">
            {getTable(TABLE_KEYS, "")}
            {getTable(
              PRO_AGENT,
              "PRO AGENT",
              { backgroundColor: "#66c44e" },
              () => setFormKey(FormFields.ADDITIONAL_INFO.PACKAGE, "PRO"),
              formData["package"] === "PRO"
            )}
          </div>
        </div>
        <OrangeRegistrationInput
          registrationKey={FormFields.ADDITIONAL_INFO.RECOMMENDED_BY}
        />
        <div>
          <input
            type="checkbox"
            onChange={(e) =>
              setFormKey(FormFields.OTHERS.TERMS_APPROVED, e.target.checked)
            }
            defaultChecked={formData[FormFields.OTHERS.TERMS_APPROVED]}
          />
          לצפייה ואישור ב<AgentTermsLink />
          {missingFields.includes(FormFields.OTHERS.TERMS_APPROVED) && (
            <div style={{ color: "red" }}>יש לאשר את התקנון</div>
          )}
        </div>
      </div>
    </RegistrationBox>
  );
};

export default PackageDetails;
