import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./paymentagent.scss";
import SplitPayment from "./SplitPayment/splitpayment";
import Crubms from "./SubComponents/crumbs";
import ClientInfo from "./SubComponents/clientsInfo";
import PassangerList from "./SubComponents/passangerList";
import HotelDisplay from "./SubComponents/hotelDisplay";
import MoreToPay from "./SubComponents/moreToPay";
import {
  getDelayedPayOrder,
  cancellationPolicies,
} from "../../../services/fhpService";
import { CardsProvider } from "../../../contexts/CardsContext";
import {
  getAgentSavedClientInfo,
  getUserInfo,
} from "../../../services/localStorage";
import HandlingFeePicker from "./HandlingFeePicker/HandlingFeePicker";
import { Loader } from "../../Loader/Loader";
import Timer from "../../../Pages/Timer/Timer";
import { useOrder } from "../../../contexts/OrderContext";
import Summary from "../OrderSummery/SubComponents/summary";
import { useAppContext } from "../../../contexts/AppContext";
import PaidCards from "../OrderSummery/SubComponents/PaidCards";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";

const PaymentAgent = () => {
  // Params
  const {
    currentHotelOrder,
    setCurrentHotelOrder,
    setHandleFeeData,
    handleFeeData,
  } = useOrder();
  const { calcBaseCommission, isGlobalConstantLoaded } = useAppContext();
  const { currentOrderId } = useParams(); // Checks if delayed order visit accured
  const [orderReqData, setOrderReqData] = useState(null);
  // Constants

  const isNewOrder = currentOrderId === "newOrder";
  const searchPhrase = JSON.parse(localStorage.getItem("currentSearchPhrase"));

  const UserInfo = getUserInfo();
  const AgentSavedClientInfo = getAgentSavedClientInfo();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [totalRooms, setTotalRooms] = useState("");
  const [numberOfNights, setNumberOfNights] = useState("");

  const [hotelPolicies, setHotelPolicies] = useState([]);
  const [hotelPoliciesRemarks, setHotelPoliciesRemarks] = useState([]);

  const { updateHandleFeeData } = useSelectedResult();

  const getTaxesAndFeesFromSearch = () => {
    if (currentOrderId === "newOrder") {
      const currentPackage = currentHotelOrder?.selectedHotel.Result.filter(
        (room) => {
          if (
            room.PackageId === currentHotelOrder?.selectedHotelPackage.packageID
          ) {
            return room;
          }
        }
      )[0];

      return currentPackage?.PackagePrice?.TaxesAndFees?.filter(
        (tax) => !tax.IsIncludedInPrice
      );
    }
  };

  const additionalTaxes = isNewOrder
    ? getTaxesAndFeesFromSearch()
    : orderReqData?.packagepricedetails?.TaxesAndFees?.filter(
        (tax) => !tax.IsIncludedInPrice
      );

  // Translator
  const { t } = useTranslation();

  // Navigator
  const navigate = useNavigate();

  const getOrderData = () => {
    const getOrder = async () => {
      let data = localStorage.getItem("UserInfo");
      const agentId = data ? JSON.parse(data).id : 0;

      const res = await getDelayedPayOrder({
        id: currentOrderId,
        agentID: agentId,
      });

      let orderData = res.data;

      if (orderData) {
        // Save order
        setOrderReqData(orderData);
        updateHandleFeeData(orderData.handle_fee_option, orderData.handlingfee);
        let tmpCurrentHotelOrder = currentHotelOrder;
        tmpCurrentHotelOrder = {
          ourPrice: calcBaseCommission(orderData?.price),
        };
        tmpCurrentHotelOrder.ourPrice.handleFeeData = {
          type: orderData?.handle_fee_option,
          value: orderData?.handlingfee,
        };
        tmpCurrentHotelOrder = {
          ...tmpCurrentHotelOrder,
          passangerData: {
            passengerInfo: orderData?.passengers,
          },
        };
        setCurrentHotelOrder(tmpCurrentHotelOrder);
      } else {
        // Reroute back to home
        navigate(`/p/dashboard/home`);
      }
      setIsLoadingData(false);
    };

    getOrder();
  };

  // --- useEffects

  // Set up css fixes for payment page - Remover of those changes is in app in agentRoutes
  useEffect(() => {
    const isPaymentPage = window.location.pathname.includes(
      "/p/dashboard/payment/"
    );

    if (isPaymentPage) {
      // Apply your styles here

      // 1 - html
      const htmlElement = document.documentElement;
      htmlElement?.classList.add("cie-auto-height");

      // 2 - agent wrapper
      const agentPagesElement = document.querySelector(".agent-pages");
      agentPagesElement?.classList.add("cie-visible-overflow");

      // 3 - side nav
      const sideNavAgentElement = document.querySelector(".SideNav-wrapper");
      sideNavAgentElement?.classList.add(
        "cie-sticky-position",
        "cie-full-height"
      );

      // 4 - top nav
      // const topNavAgentElement = document.querySelector(
      //   ".upper-nav-bar-agent-wrapper"
      // );
      // topNavAgentElement?.classList.add("cie-height-8vh");

      // 5 - payment component
      const paymentAgentElement = document.querySelector(".payment-agent");
      paymentAgentElement?.classList.add("cie-padding-7vh");
    } else {
      // Undo your styles here

      // 1 - html
      const htmlElement = document.documentElement;
      htmlElement?.classList.remove("cie-auto-height");

      // 2 - agent wrapper
      const agentPagesElement = document.querySelector(".agent-pages");
      agentPagesElement?.classList.remove("cie-visible-overflow");

      // 3 - side nav
      const sideNavAgentElement = document.querySelector(".side-nav-agent");
      sideNavAgentElement?.classList.remove(
        "cie-sticky-position",
        "cie-full-height"
      );

      // 4 - top nav
      // const topNavAgentElement = document.querySelector(
      //   ".upper-nav-bar-agent-wrapper"
      // );
      // topNavAgentElement?.classList.remove("cie-height-8vh");

      // 5 - payment component
      const paymentAgentElement = document.querySelector(".payment-agent");
      paymentAgentElement?.classList.remove("cie-padding-7vh");
    }
  }, []);

  // Check if its a delayed pay if yes get the data of the order
  useEffect(() => {
    let b = false;

    // Check if tis an actuall order id
    if (currentOrderId !== "newOrder") {
      if (/^\d+$/.test(currentOrderId)) {
        b = true;
      } else {
        if (currentOrderId === "newOrder") {
          b = true;
        } else {
          navigate("/p/dashboard/searchoffer");
        }
      }
    }

    // Checks that the orderid is valid and that its not a new order
    if (b && currentOrderId !== "newOrder") {
      getOrderData();
    } else {
      // If its a new order but somthing went wrong redirect the user
      setIsLoadingData(false);

      if (!currentHotelOrder) {
        navigate("/p/dashboard/searchoffer");
      }
    }
  }, [currentOrderId]);

  // Calculate total price
  // Get Basic info about hotel
  useEffect(() => {
    if (currentOrderId === "newOrder") {
      // Extract search info
      extractDataFromSearch(searchPhrase);
    } else {
      // Extract order info
      extractDataFromOrderInfo(orderReqData);
    }
  }, [orderReqData]);

  // --- Functions

  const getCancellationDataForSearch = async () => {
    const data = {
      session: searchPhrase?.session,
      hotelID: parseInt(searchPhrase?.hotelID),
      packageID: currentHotelOrder.selectedHotelPackage.packageID,
    };

    const res = await cancellationPolicies(data);

    setHotelPolicies(res.data.policies);
    setHotelPoliciesRemarks(res.data.remarks);
  };

  // Get Rooms | Nights Amount - current Search Order
  const extractDataFromSearch = (data) => {
    const { rooms } = data;

    if (rooms && rooms.length > 0) {
      const totalRooms = rooms.length;

      // Get number of nights
      const checkInParts = data?.checkIn.split("/");
      const checkOutParts = data?.checkOut.split("/");

      // Create Date objects using parts in the format: year, month - 1, day
      const checkInDate = new Date(
        checkInParts[2],
        checkInParts[1] - 1,
        checkInParts[0]
      );
      const checkOutDate = new Date(
        checkOutParts[2],
        checkOutParts[1] - 1,
        checkOutParts[0]
      );

      // Calculate the time difference in milliseconds
      const timeDifference = checkOutDate - checkInDate;

      // Convert milliseconds to days
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      getCancellationDataForSearch();
      setTotalRooms(totalRooms);
      setNumberOfNights(numberOfNights);
    }
  };

  // Get Rooms Nights Amount - current order in upComingOrders
  const extractDataFromOrderInfo = (data) => {
    if (data) {
      // Get number of nights
      const checkInParts = data.checkin.split("/");
      const checkOutParts = data.checkout.split("/");

      // Create Date objects using parts in the format: year, month - 1, day
      const checkInDate = new Date(
        checkInParts[2],
        checkInParts[1] - 1,
        checkInParts[0]
      );
      const checkOutDate = new Date(
        checkOutParts[2],
        checkOutParts[1] - 1,
        checkOutParts[0]
      );

      // Calculate the time difference in milliseconds
      const timeDifference = checkOutDate - checkInDate;

      // Convert milliseconds to days
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      setTotalRooms(data.rooms.length);
      setNumberOfNights(numberOfNights);
    }
  };

  function renderSummery() {
    if (isLoadingData) {
      return null;
    }
    const defaultParams = {
      numberOfNights: numberOfNights,
      totalRooms: totalRooms,
    };

    const params =
      currentOrderId === "newOrder" && currentHotelOrder
        ? {
            ...defaultParams,
          }
        : {
            ...defaultParams,
            sumPaid: orderReqData?.sumpaid,
          };

    return (
      <div>
        <Summary {...params} orderReqData={orderReqData} />
        <PaidCards />
      </div>
    );
  }

  if (isLoadingData || !currentHotelOrder || !isGlobalConstantLoaded) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Loader size="large" />
      </div>
    );
  }
  return (
    <>
      <CardsProvider
        totalPrice={currentHotelOrder?.ourPrice?.priceWithCreditCardFee || 0}
      >
        <div className="payment-agent tw-w-full lg:tw-w-[85%]">
          {(currentHotelOrder || orderReqData) && (
            <>
              {isNewOrder ? <Timer /> : <></>}
              {/* Title */}
              <div className="payment-title-wrapper">
                <p className="payment-title">{t("paymentagent.summery")}</p>
              </div>

              {/* Content */}
              <div className="payment-wrapper-wrapper tw-flex tw-flex-col-reverse lg:tw-flex-row">
                {/* Order Summery */}
                <div className="payment-order-summery lg:tw-w-[35%]">
                  {/* Crumbs */}
                  {currentOrderId === "newOrder" && currentHotelOrder ? (
                    <Crubms
                      agent={UserInfo?.firstname || ""}
                      client={AgentSavedClientInfo?.fullname || ""}
                    />
                  ) : (
                    !isLoadingData && (
                      <Crubms
                        agent={UserInfo?.firstname || ""}
                        client={orderReqData?.fullname}
                        orderId={currentOrderId}
                      />
                    )
                  )}

                  {/* Details */}
                  <div className="ci-clientInfo-main-wrapper">
                    <label className="ci-clientInfo-title">
                      להלן פרטי ההזמנה עבור טיסה ומלון
                    </label>

                    {currentOrderId === "newOrder" && currentHotelOrder ? (
                      <ClientInfo
                        name={
                          localStorage.getItem("agent-saved-client-info")
                            ? JSON.parse(
                                localStorage.getItem("agent-saved-client-info")
                              ).fullname
                            : ""
                        }
                        cell={
                          localStorage.getItem("agent-saved-client-info")
                            ? JSON.parse(
                                localStorage.getItem("agent-saved-client-info")
                              ).phone
                            : ""
                        }
                        email={
                          localStorage.getItem("agent-saved-client-info")
                            ? JSON.parse(
                                localStorage.getItem("agent-saved-client-info")
                              ).email
                            : ""
                        }
                      />
                    ) : (
                      !isLoadingData && (
                        <ClientInfo
                          name={orderReqData?.fullname}
                          cell={orderReqData?.cell}
                          email={orderReqData?.email}
                        />
                      )
                    )}
                  </div>

                  {/* Passanger List */}
                  {currentOrderId === "newOrder" && currentHotelOrder ? (
                    <PassangerList
                      passengers={
                        currentHotelOrder?.passangerData?.passengerInfo
                          ? currentHotelOrder.passangerData?.passengerInfo
                          : []
                      }
                      editInfo={false}
                    />
                  ) : (
                    !isLoadingData && (
                      <PassangerList
                        passengers={
                          orderReqData?.passengers
                            ? JSON.parse(orderReqData?.passengers)
                            : []
                        }
                        editInfo={true}
                      />
                    )
                  )}

                  {renderSummery()}
                  <HandlingFeePicker
                    updateHandleFeeData={setHandleFeeData}
                    handleFeeData={handleFeeData}
                  />

                  {/* Hotel Display */}
                  {currentOrderId === "newOrder" && currentHotelOrder ? (
                    <HotelDisplay
                      price={currentHotelOrder.ourPrice}
                      commission={currentHotelOrder?.ourPrice?.handleFee || 0}
                      date={
                        currentHotelOrder.searchPhrase?.checkIn +
                        " - " +
                        currentHotelOrder.searchPhrase.checkOut
                      }
                      hotelName={currentHotelOrder.selectedHotel.HotelName}
                      hotelID={currentHotelOrder.searchPhrase.hotelID}
                      hotelAddress={currentHotelOrder.selectedHotel.Address}
                      numberOfNights={numberOfNights}
                      totalRooms={totalRooms}
                      roomName={
                        currentHotelOrder.selectedHotelPackage.roomNames[0]
                      }
                      rating={currentHotelOrder.selectedHotel.Rating}
                      passengers={
                        currentHotelOrder
                          ? currentHotelOrder.passangerData.passengerInfo
                          : ""
                      }
                      editInfo={false}
                      basis={currentHotelOrder.selectedHotelPackage.roomBasis}
                      freeCancelation={
                        currentHotelOrder.selectedHotelPackage.refundability
                      }
                      dateOfCancelation={
                        currentHotelOrder.selectedHotelPackage.refundableUntil
                      }
                      includestaxes={
                        currentHotelOrder.selectedHotelPackage.includesTaxes
                      }
                      packageID={
                        currentHotelOrder.selectedHotelPackage.packageID
                      }
                      images={currentHotelOrder?.selectedRoom?.images}
                      policies={hotelPolicies}
                      policyRemarks={hotelPoliciesRemarks}
                    />
                  ) : (
                    !isLoadingData && (
                      <HotelDisplay
                        commission={currentHotelOrder?.ourPrice?.handleFee || 0}
                        date={
                          orderReqData?.checkin + " - " + orderReqData?.checkout
                        }
                        hotelName={orderReqData?.hotelname}
                        hotelID={orderReqData?.hotelid}
                        hotelAddress={orderReqData?.hoteladdress}
                        numberOfNights={numberOfNights}
                        totalRooms={totalRooms}
                        roomName={orderReqData?.rooms[0].roomname}
                        rating={1}
                        passengers={
                          orderReqData?.passengers
                            ? JSON.parse(orderReqData?.passengers)
                            : []
                        }
                        editInfo={true}
                        basis={
                          orderReqData?.rooms
                            ? orderReqData?.rooms[0].roombasis
                            : "RO"
                        }
                        freeCancelation={orderReqData?.refundability}
                        dateOfCancelation={orderReqData?.refundableuntil}
                        includestaxes={orderReqData?.includestaxes}
                        policies={JSON.parse(orderReqData?.policies || "[]")}
                        price={currentHotelOrder.ourPrice}
                        policyRemarks={orderReqData?.policyremarks}
                        images={orderReqData?.rooms[0]?.images}
                      />
                    )
                  )}
                  {/* More Payments */}
                  {currentOrderId === "newOrder" && currentHotelOrder ? (
                    <MoreToPay additionalTaxes={additionalTaxes} />
                  ) : (
                    !isLoadingData && (
                      <MoreToPay additionalTaxes={additionalTaxes} />
                    )
                  )}
                </div>

                {/* Purchase Form */}
                <div className="payment-wrapper payment-payment-details-wrapper lg:tw-w-[75%]">
                  {/* Credit Card info */}
                  <SplitPayment
                    orderId={currentOrderId}
                    handlingFee={currentHotelOrder?.ourPrice?.handleFee || 0}
                    orderReqData={orderReqData}
                    setOrderReqData={setOrderReqData}
                    cardFee={currentHotelOrder?.ourPrice?.creditCardFee || 0}
                    baseCommissions={
                      currentHotelOrder?.ourPrice?.hrCommission || 0
                    }
                    packageId={
                      currentHotelOrder?.selectedHotelPackage?.packageID
                    }
                    isNewOrder={currentOrderId === "newOrder"}
                    getOrderData={getOrderData}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </CardsProvider>
    </>
  );
};

export default PaymentAgent;
