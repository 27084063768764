import React from "react";
import HotelRow from "./HotelRow";
import "./HotelsTable.scss";

import { useTranslation } from "react-i18next";
import { isAdminUser } from "../../../../services/authService";
import HotelCard from "./HotelCard";

const HotelsTable = ({
  allOrders = [],
  orders = [],
  cancelOrder,
  setOrders,
}) => {
  const { t } = useTranslation();
  const isAdmin = isAdminUser();

  return (
    <div className="hotels-cards">
      {orders.map((row, index) => (
        <div key={index}>
          <HotelCard
            orders={allOrders}
            orderId={row.id}
            cancelOrder={cancelOrder}
            key={row.id}
            setOrders={setOrders}
            row={row}
            index={index}
          />
        </div>
      ))}
    </div>
  );
};

export default HotelsTable;
