import React, { useState, useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import doneImg from "../../../assets/images/passangerData/done.png";
import CustomLoading from "../../GeneralComponents/CustomLoading/customloading";
import { parseNumber } from "../../../services/currencyService";
import { PaymentsAmountInput } from "./CardPaymentInputs";
import PaymentInterest from "./PaymentInterest";
import PaymentIcons from "./PaymentIcons";
import { handleInputChange } from "../../../utils/PaymentHelpers";
import "./CardPayment.scss";
import CardPaidOverlay from "./CardPaidOverlay";
// import { Loader } from "../../Loader/Loader";
import { useCards } from "../../../contexts/CardsContext";
import { Button, message, Popconfirm } from "antd";
import {
  createLdsPaymentLink,
  removeLdsPaymentLink,
  getLdsOrder,
} from "../../../services/fhpService";
import { useNavigate } from "react-router-dom";

const CardPayment = ({
  cardIndex,
  paymentLink,
  currentOrderId,
  setOrderReqData,
}) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const { cards, updateCards } = useCards();
  const { t } = useTranslation();
  const [currentError, setCurrentError] = useState(false);
  const [expandCard, setExpandCard] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [paymentLinkOpen, setPaymentLinkOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const currentCard = cards[cardIndex];
  const { paid: currentCardPaid } = currentCard;
  const cardDisabled = currentCardPaid;

  // 1. Auto open first card
  useEffect(() => {
    if (cardIndex === 0) {
      setExpandCard(true);
    }
  }, []);

  useEffect(() => {
    if (
      paymentLink &&
      (cards[cardIndex].amount !== paymentLink.actualAmount ||
        cards[cardIndex].paid !== paymentLink.paid)
    ) {
      const thisCardTmp = cards[cardIndex];
      thisCardTmp.numberofpayments = Number(paymentLink.tash);
      thisCardTmp.amount = Number(paymentLink.actualAmount);
      thisCardTmp.paid = paymentLink.paid;
      const cardsTmp = [...cards];
      cardsTmp[cardIndex] = thisCardTmp;
      updateCards(cardsTmp);
      if (paymentLink.paid) setPaymentLinkOpen(false);
      else if (cardsTmp.length === 1) setPaymentLinkOpen(true);
    }
  }, [paymentLink, cards[cardIndex]]);

  // --- Functions
  const toggleExpantion = () => {
    setExpandCard(!expandCard);
  };

  // Mark and un mark inputs
  const handleMarkEdit = (e) => e.target?.classList.add("ci-input-editing");

  const handleUnMarkEdit = (e) =>
    e.target?.classList.remove("ci-input-editing");

  // Save changes for inputs
  const onInputChange = (e) => {
    handleInputChange(e, cards, updateCards, cardIndex);
  };

  const handlePaymentsChange = (e) => {
    const newPaymentsAmount = parseInt(e.target.value);
    let tempCards = cards;
    tempCards[cardIndex].numberofpayments = newPaymentsAmount;
    updateCards(tempCards);
  };

  const handlePayment = async (e) => {
    if (currentCard.amount > 0) {
      setSubmitLoading(true);
      try {
        createLdsPaymentLink(currentCard, currentOrderId)
          .then((res) => {
            res
              .json()
              .then((data) => {
                if (data.status === "ok" && data.order) {
                  setOrderReqData(data.order);
                  message.success("קישור לתשלום נוצר בהצלחה");
                } else {
                  message.error("שגיאה ביצירת קישור לתשלום");
                }
              })
              .catch((err) => {
                message.error("שגיאה ביצירת קישור לתשלום");
              })
              .finally(() => {
                setSubmitLoading(false);
              });
          })
          .catch((err) => {
            message.error("שגיאה ביצירת קישור לתשלום");
            setSubmitLoading(false);
          });
      } catch (error) {
        console.log(error);
        message.error("שגיאה ביצירת קישור לתשלום");
        setSubmitLoading(false);
      }
    }
    // setCurrentError(false);
    // setLoading(true);
    // pay(currentCard)
    //   .then((res) => {
    //     if (res) {
    //       let tempCards = JSON.parse(JSON.stringify(cards));
    //       tempCards[cardIndex].paid = true;
    //       updateCards(tempCards);
    //     }
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
    // setSubmitLoading(true);
  };

  const renderExpandedCard = () => {
    const defaultProps = {
      handleMarkEdit,
      handleUnMarkEdit,
      handleInputChange: onInputChange,
      disabled: cardDisabled,
    };
    return (
      <div
        className="payment-card-details tw-p-5  tw-flex tw-flex-col tw-gap-[10px]"
        style={{ position: "relative" }}
      >
        {currentCardPaid ? <CardPaidOverlay /> : null}

        {/* Amount & Num of payments */}
        <PaymentsAmountInput
          {...defaultProps}
          value={parseNumber(currentCard?.amount || 0)}
          handlePaymentsChange={handlePaymentsChange}
          totalAmountWithPaymentsFee={currentCard.amountWithInterest}
          numberofpayments={currentCard.numberofpayments}
          disabled={paymentLink ? true : false}
        />

        {currentCard.numberofpayments > 1 ? (
          <div className="ci-a-nop">
            <PaymentInterest currentCard={currentCard} />
          </div>
        ) : null}

        {/* Submit button */}
        <div className="ci-submit">
          {paymentLink ? (
            !paymentLink.paid ? (
              <div
                className="buttons"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Button
                  type="dashed"
                  onClick={() => {
                    // copy payment url
                    navigator.clipboard.writeText(paymentLink.url);
                    message.success("קישור לתשלום הועתק בהצלחה");
                  }}
                  children="העתק קישור לתשלום"
                />
                <Button
                  type="primary"
                  onClick={() => {
                    // Open payment url in new tab
                    setPaymentLinkOpen(true);
                  }}
                  children="שלם עכשיו"
                  disabled={paymentLink.paid}
                />
                <Popconfirm
                  title="האם אתה בטוח שברצונך למחוק את התשלום?"
                  onConfirm={() => {
                    setIsRequesting(true);
                    removeLdsPaymentLink(paymentLink, currentOrderId).then(
                      async (res) => {
                        if (res.status === 200) {
                          const order = await res.json();
                          if (order && order.id) setOrderReqData(order);
                          message.success("תשלום נמחק בהצלחה");
                        } else {
                          message.error("שגיאה במחיקת תשלום");
                        }
                        setIsRequesting(false);
                      }
                    );
                  }}
                  okText="כן"
                  cancelText="לא"
                >
                  <Button
                    type="primary"
                    danger
                    children="מחק תשלום"
                    loading={isRequesting}
                  />
                </Popconfirm>
              </div>
            ) : (
              <label
                style={{
                  color: "green",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {t("paymentagent.payment-done")}
              </label>
            )
          ) : !submitLoading ? (
            <button
              type="submit"
              className="credit-card-checkout-btn"
              onClick={(e) => {
                e.preventDefault();
                handlePayment(e);
              }}
            >
              {t("paymentagent.create-pay-link")}
            </button>
          ) : (
            <CustomLoading
              classLoading={"credit-card-checkout-btn-loading"}
              labelLoading={t("paymentagent.create-pay-link")}
            />
          )}
        </div>

        {/* Pics */}
        <PaymentIcons />

        {/* Error / Success messege */}
        <div className="ci-messeger">
          {/*<label*/}
          {/*  id={`messege-${cardIndex}-suc`}*/}
          {/*  className="ci-messeger-success"*/}
          {/*>*/}
          {/*  כרטיס האשראי עבר בהצלחה*/}
          {/*</label>*/}
          {!currentError ? null : (
            <label className="payment-error">
              <div> כרטיס האשראי לא עבר</div>
              <div>({currentError})</div>
            </label>
          )}
        </div>
        {/*</form>*/}
        {/*</div>*/}
      </div>
    );
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="splitPayment-wrapper">
        <div
          className="splitPayment-card-extand-wrapper"
          onClick={toggleExpantion}
        >
          <div className="splitPayment-card-extand-item">
            <img
              className="splitPayment-card-extand-img"
              src={doneImg}
              alt="Done"
            ></img>
            <span className="splitPayment-card-extand-title">
              {t("paymentagent.cc")}: {cardIndex + 1}
            </span>
          </div>

          <div className="credit-card-left-topay-wrapper">
            <div
              className="credit-card-left-topay"
              style={{ opacity: currentCardPaid ? "1" : "0" }}
            >
              <label>סה"כ שולם:</label>
              <label>{currentCard.amountWithInterest}</label>
            </div>

            <div className="splitPayment-card-extand-item">
              {!expandCard ? (
                <BsChevronDown></BsChevronDown>
              ) : (
                <BsChevronUp></BsChevronUp>
              )}
              {paymentLinkOpen && (
                <div
                  className="close-payment"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPaymentLinkOpen(false);
                  }}
                >
                  X
                </div>
              )}
            </div>
          </div>
        </div>
        {expandCard &&
          (paymentLinkOpen && paymentLink && !paymentLink.paid ? (
            <iframe
              title={`payment-link-${cardIndex}`}
              src={paymentLink.url}
              style={{ width: "100%", height: 500, border: "none" }}
              onLoad={(e) => {
                getLdsOrder({ id: currentOrderId }).then((res) => {
                  // check if payment status chanced for current payment
                  const payment = res.data.payments.find(
                    (p) =>
                      p.amount === paymentLink.amount &&
                      p.tash === paymentLink.tash &&
                      p.paid !== paymentLink.paid
                  );
                  if (payment) {
                    // update payment link
                    setOrderReqData(res.data);
                  }
                });
              }}
            />
          ) : (
            renderExpandedCard()
          ))}
      </div>
    </>
  );
};

export default CardPayment;
