import React from "react";
import "./HotelCard.css";
import { GoogleMapSecretKey } from "../../../../assets/GlobalVariables";
import LocationMap from "../../../../Pages/Client/HotelProfile/Components/LocationMap/locationmap";
import { useTranslation } from "react-i18next";
import hotelSVG from "../../../../assets/images/card/hotel.svg";
import arrowDownSVG from "../../../../assets/images/card/arrow-down.svg";
import CollapsedHotelRow from "./CollapsedHotelRow";

const DatesCellRender = ({ checkIn, checkOut }) => {
  return (
    <div>
      {checkIn} - {checkOut}
    </div>
  );
};

const HotelName = ({ name }) => {
  return <div className="blue-name-cell">{name}</div>;
};

const HotelCard = ({
  orders,
  orderId,
  row,
  index,
  cancelOrder,
  setOrders,
} = {}) => {
  const [hotelImage, setHotelImage] = React.useState(null);
  // const [hotelCountry, setHotelCountry] = React.useState("");
  const [hotelLongLat, setHotelLongLat] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { hotelid, hoteladdress, hotelname, checkin, checkout } = row;

  const getHotelImage = async (hotel_id) => {
    return await fetch("https://api.hotelsriver.com/getImage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hotelID: hotel_id }),
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      });
  };

  async function getGeolocation(address) {
    try {
      // const language = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'he';
      const language = "en";

      const encodedAddress = encodeURIComponent(address);
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${GoogleMapSecretKey}&language=${language}`;

      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        const country = result.address_components.find((component) =>
          component.types.includes("country")
        ).long_name;

        const location = result.geometry.location;
        const latitude = location.lat;
        const longitude = location.lng;

        return { country, latitude, longitude };
      } else {
        throw new Error("Unable to retrieve geolocation data");
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error.message);
      throw error;
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (hotelid) {
          const data = await getHotelImage(hotelid);
          setHotelImage(data.image);
        }

        if (hoteladdress) {
          const result = await getGeolocation(hoteladdress);
          // setHotelCountry(result.country);
          setHotelLongLat({ lat: result.latitude, lng: result.longitude });
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchData();
  }, [hotelid, hoteladdress]);

  const translate = useTranslation();

  return (
    <React.Fragment>
      <div className="card">
        <div
          className={
            "order-status" +
            (row.cancelled ? " cancelled" : row.paid ? " paid" : " pending")
          }
        >
          {row.cancelled
            ? translate.t("orders.cancelled")
            : row.paid
              ? translate.t("orders.paid")
              : translate.t("orders.pending")}
        </div>
        <div className="image-and-map">
          <img className="image" src={hotelImage} alt="hotelname" />
          {hotelLongLat != null ? (
            <div className="map">
              <LocationMap
                lat={hotelLongLat.lat}
                lng={hotelLongLat.lng}
                mapId={"map-" + index}
              />
            </div>
          ) : null}
        </div>
        <div
          className={"info-open-card" + (isOpen ? " open" : "")}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="info">
            <div className="icon-name-date">
              <img className="icon" src={hotelSVG} alt="hotel" />
              <div className="name-and-dates">
                <div className="name">
                  <HotelName name={hotelname} />
                </div>
                <div className="dates">
                  <DatesCellRender checkIn={checkin} checkOut={checkout} />
                </div>
              </div>
            </div>
            <div className="cancelation">
              <div className="item-wrapper">
                <div className="item-header">
                  {translate.t("orders.refundableUntil")}
                </div>
                <div className="cancel-until-cell">{row.refundableuntil}</div>
              </div>
            </div>
          </div>
          <div className="open-text">
            <div className="text">
              {isOpen
                ? translate.t("hotelcard.closecard")
                : translate.t("hotelcard.opencard")}
            </div>
            <img className="icon" src={arrowDownSVG} alt="open/close" />
          </div>
        </div>
        {isOpen ? (
          <CollapsedHotelRow
            updateOrder={(order) => {
              let tempOrders = [...orders];
              const orderIndex = tempOrders.findIndex(
                (order) => order.id === orderId
              );
              tempOrders[orderIndex] = order;
              setOrders(tempOrders);
            }}
            cancelOrder={cancelOrder}
            row={row}
            index={index}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default HotelCard;
