import React, { useState, useEffect } from "react";
import HotelsTable from "./OrdersTable/HotelsTable/HotelsTable";
import PackageTable from "./OrdersTable/PackageTable/PackageTable";
import OrdersFilters from "./OrdersFilters";
import {
  getOrdersByEmail,
  cancelOrderByOrderId,
  getPackagesOrders,
} from "../../services/fhpService";
import { Loader } from "../Loader/Loader";

// CSS
import "./Orders.css";

// Languages
import { useTranslation } from "react-i18next";

const getHotelsFromServer = async () => {
  const agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;
  let ordersData = await getOrdersByEmail({
    email: agentEmail,
  });
  return ordersData?.data?.orders;
};

const getPackgesFromServer = async () => {
  const agentId = JSON.parse(localStorage.getItem("UserInfo")).id;
  const res = await getPackagesOrders(agentId);
  return res?.data;
};

const Orders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(1);

  const loadOrders = () => {
    if (isLoading) return;
    const fetchFunc = TABS[currentTabIndex].fetchFunc;
    setIsLoading(true);
    fetchFunc &&
      fetchFunc().then((res) => {
        if (orders === null) setOrders(res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setOrders(null);
  }, [currentTabIndex]);

  useEffect(() => {
    loadOrders();
    setFilteredOrders(orders);
  }, [orders]);

  const cancelOrder = async ({ orderId, segmentId }) => {
    await cancelOrderByOrderId({ orderID: orderId, segmentId });
    loadOrders();
  };

  const TABS = [
    { index: 0, component: <div>בבנייה</div> },
    {
      index: 1,
      component: (
        <HotelsTable
          allOrders={orders}
          setOrders={setOrders}
          orders={filteredOrders}
          cancelOrder={cancelOrder}
        ></HotelsTable>
      ),
      fetchFunc: getHotelsFromServer,
    },
    {
      index: 2,
      component: <div>בבנייה</div>,
    },
    {
      index: 3,
      component: (
        <PackageTable
          orders={filteredOrders}
          allOrders={orders}
          setOrders={setOrders}
        />
      ),
      fetchFunc: getPackgesFromServer,
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="orders-table-wrapper lg:tw-mt-10">
      <OrdersFilters
        isLoading={isLoading}
        orders={orders}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
        setFilteredOrders={setFilteredOrders}
      />
      <div className="orders">
        {isLoading ? (
          <div
            style={{
              height: "50vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : !filteredOrders?.length ? (
          <div className="no-data">אין הזמנות</div>
        ) : (
          TABS[currentTabIndex].component
        )}
      </div>
    </div>
  );
};

export default Orders;
