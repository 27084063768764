import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// React Icons
import { BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./creditcarddetails.css";

// Images
import doneImg from "../../../../../../assets/images/passangerData/done.png";

// Services
import {
  convertPrice,
  parseNumber,
} from "../../../../../../services/currencyService";

// Custom Functions
// import CustomAlertAgent from "../../../../../GeneralComponents/CustomAlertAgent/customalertagent";
import CustomLoading from "../../../../../GeneralComponents/CustomLoading/customloading";

// Icons
import visaIcon from "../../../../../../assets/images/payment/visa.png";
import visa1Icon from "../../../../../../assets/images/payment/visa1.png";
import MasterCardIcon from "../../../../../../assets/images/payment/card.png";
import AmericanExpressIcon from "../../../../../../assets/images/payment/american-express.png";
import { useCards } from "../../../../../../contexts/CardsContext";
import { ApiReqBaseUrl } from "../../../../../../assets/GlobalVariables";
import { Button, message } from "antd";

const CreditCardDetails = ({
  cardNumber,
  setOrderReqData,
  paymentLink,
  priceToPay,
  paymentLinksTotalToPay,
  cardIndex,
  getOrderData,
}) => {
  const { t } = useTranslation();
  const { currentOrderId } = useParams();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [expandCard, setExpandCard] = useState(true);
  const { cards, updateCards } = useCards();
  const [nop, setNop] = useState(1);
  const currentCard = cards[cardIndex];

  const currentCardPaid = currentCard.amount;

  const [paymentLinkOpen, setPaymentLinkOpen] = useState(false);

  // 1. Auto open first card
  useEffect(() => {
    if (cardNumber === 1) {
      setExpandCard(true);
    }
  }, []);

  useEffect(() => {
    if (
      paymentLink &&
      (cards[cardIndex].amount !== paymentLink.actualAmount ||
        cards[cardIndex].paid !== paymentLink.paid)
    ) {
      const thisCardTmp = cards[cardIndex];
      thisCardTmp.numberofpayments = Number(paymentLink.tash);
      thisCardTmp.amount = Number(paymentLink.actualAmount);
      thisCardTmp.paid = paymentLink.paid;
      const cardsTmp = [...cards];
      cardsTmp[cardIndex] = thisCardTmp;
      updateCards(cardsTmp);
      if (paymentLink.paid) setPaymentLinkOpen(false);
      else if (cardsTmp.length === 1) setPaymentLinkOpen(true);
    }
  }, [paymentLink, cards[cardIndex]]);

  // --- Functions
  const cardExpandor = () => {
    setExpandCard(!expandCard);
  };

  // Mark and un mark inputs
  const handleMarkEdit = (e) => {
    e.target?.classList.add("ci-input-editing");
  };

  const handleUnMarkEdit = (e) => {
    e.target?.classList.remove("ci-input-editing");
  };

  // Save changes for inputs
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    let newCardAmount = null;
    if (name === "amount") {
      newCardAmount = parseFloat(value);
      if (cards.length === 1) {
        return;
      }
      if (
        newCardAmount > priceToPay - paymentLinksTotalToPay ||
        newCardAmount < 0
      ) {
        return false;
      }
    }

    // Change caret color to default when the user starts typing
    if (value.length > 0) {
      e.target.style.caretColor = "auto";
    } else {
      e.target.style.caretColor = "transparent";
    }

    if (name === "cardnumber") {
      // Make spacing
      value = formatCreditCardNumber(value);
    }

    if (name === "expdate") {
      // Make automatic '/'
      if (value.length === 2 && value.indexOf("/") === -1) {
        value += "/";
      }
    }

    const updatedCards = [...cards];
    const updatedCard = {
      ...cards[cardNumber - 1],
      [name]: newCardAmount ?? value,
    };
    updatedCards[cardNumber - 1] = updatedCard;

    updateCards(updatedCards);
  };

  const formatCreditCardNumber = (value) => {
    const regex = /(\d{1,4})/g;
    const parts = value.match(regex);

    if (parts) {
      const formattedValue = parts.join(" ");

      return formattedValue;
    }
  };

  const handlePaymentsChange = (e) => {
    if (paymentLink) return;
    const newPaymentsNumber = e.target.value;
    let tempCards = JSON.parse(JSON.stringify(cards));
    const tmpCurCard = tempCards[cardNumber - 1];
    tmpCurCard.numberofpayments = newPaymentsNumber;
    setNop(newPaymentsNumber);
    updateCards(tempCards);
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    if (currentCard.amount > 0) {
      setSubmitLoading(true);
      fetch(ApiReqBaseUrl + "/getGimmonixPaymentUrl", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount:
            currentCard.numberofpayments > 1
              ? currentCard.amountWithInterest.toFixed(2)
              : currentCard.amount,
          actualAmount: currentCard.amount,
          currency: "ILS",
          orderId: currentOrderId,
          tash: currentCard.numberofpayments,
        }),
      }).then((res) => {
        res
          .json()
          .then((data) => {
            if (data.status === "ok" && data.order) {
              setOrderReqData(data.order);
              message.success("קישור לתשלום נוצר בהצלחה");
            } else {
              message.error("שגיאה ביצירת קישור לתשלום");
            }
          })
          .catch((err) => {
            message.error("שגיאה ביצירת קישור לתשלום");
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      });
    }
  };

  const renderPaymentsFee = () => {
    const { amount, numberofpayments } = currentCard;
    const totalPayment = parseNumber(
      parseFloat(amount) + parseFloat(currentCard.paymentInterest)
    );
    if (!amount) {
      return null;
    }
    return (
      <span style={{ color: "black", fontSize: 16 }}>
        {`שים לב, לתשלום תתווסף ריבית תשלומים בסך`}
        <span style={{ color: "red", margin: "0 5px", fontWeight: "bold" }}>
          {convertPrice(currentCard.paymentInterest)}
        </span>
        <div style={{ fontSize: 16, color: "grey" }}>
          {`(${convertPrice(totalPayment)} = ${numberofpayments} חודשים * ${convertPrice(totalPayment / numberofpayments)})`}
        </div>
      </span>
    );
  };

  // const iframeRef = useRef(null);

  // const handleIframeLoad = () => {
  //   if (iframeRef.current) {
  //     const iframeWindow = iframeRef.current.contentWindow;
  //     const currentUrl = iframeWindow.location.href;
  //     if (currentUrl.includesTaxes("payment-success")) {
  //       getOrderData();
  //     }
  //   }
  // };

  return (
    <>
      <div className="splitPayment-wrapper">
        <div
          className="splitPayment-card-extand-wrapper"
          onClick={cardExpandor}
        >
          <div className="splitPayment-card-extand-item">
            <img className="splitPayment-card-extand-img" src={doneImg}></img>
            <span className="splitPayment-card-extand-title">
              {t("paymentagent.cc")}: {cardNumber}
            </span>
          </div>

          <div className="credit-card-left-topay-wrapper">
            <div
              className="credit-card-left-topay"
              style={{ opacity: currentCardPaid ? "1" : "0" }}
            >
              <label>סה"כ שולם:</label>
              <label>
                {currentCard.paid ? currentCard.amount.toFixed(2) : 0}
              </label>
            </div>

            <div className="splitPayment-card-extand-item">
              {!expandCard ? (
                <BsChevronDown></BsChevronDown>
              ) : (
                <BsChevronUp></BsChevronUp>
              )}
            </div>

            {paymentLinkOpen ? (
              <BsX
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  setPaymentLinkOpen(false);
                }}
                style={{
                  rotate: "180deg",
                  height: "20px",
                  width: "20px",
                }}
              ></BsX>
            ) : null}
          </div>
        </div>

        {/* Card Shower */}
        {expandCard &&
          (paymentLinkOpen ? (
            <iframe
              // ref={iframeRef}
              title={`payment-link-${cardIndex}`}
              src={paymentLink.url}
              style={{ width: "100%", height: "1000px" }}
              onLoad={() => {
                getOrderData();
              }}
            ></iframe>
          ) : (
            <div className="payment-card-details lg:tw-w-[calc(100%_-_80px)] lg:tw-py-[20px] lg:tw-px-[40px] tw-flex tw-flex-col tw-gap-[10px]">
              {/* Card Cataloger */}
              <div className="splitPayment-card-displayer-item">
                <form
                  id={`card-number-${cardNumber}`}
                  onSubmit={handlePayment}
                  className={`splitPayment-card lg:tw-px-[15%]`}
                >
                  {/* Left to pay */}
                  {/* <div className="ci-ltp">
                    <label className="ci-ltp-title">
                      {t("paymentagent.lefttopay")}:
                    </label>
                    <label id="price-leftToPay" className="ci-ltp-value">
                      {convertPrice(parseFloat(priceToPay))}
                    </label>
                  </div> */}

                  {/* Amount & Num of payments */}
                  <div className="ci-a-nop">
                    <div
                      className="ci-a-nop-item"
                      style={{ display: "row", flexWrap: "nowrap" }}
                    >
                      {currentCard.numberofpayments > 0 ? (
                        <input
                          name="amount"
                          type="number"
                          id="price-amount"
                          className="payment-agent-input payment-agent-input-amount"
                          onChange={handleInputChange}
                          onFocus={handleMarkEdit}
                          onBlur={handleUnMarkEdit}
                          placeholder={t("paymentagent.amount")}
                          value={parseNumber(currentCard.amount)}
                          disabled={currentCard.paid || paymentLink}
                          required
                        ></input>
                      ) : null}
                    </div>

                    <div className="ci-a-nop-item-nop">
                      <label className="ci-a-nop-item-label">
                        {t("paymentagent.numberofpayments")}:
                      </label>
                      <select
                        name="splitPayment-card-amount-input"
                        id="splitPayment-card-amount-input"
                        // disabled={currentCardPaid}
                        onChange={handlePaymentsChange}
                        disabled={paymentLink}
                        value={nop}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                  </div>
                  {currentCard.numberofpayments > 1 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        fontSize: 20,
                        marginRight: 10,
                      }}
                    >
                      {"תשלום סופי:"}
                      <span style={{ fontWeight: "bold", marginRight: 5 }}>
                        {currentCard.amountWithInterest.toFixed(2)}
                      </span>
                    </div>
                  ) : null}
                  {currentCard.numberofpayments > 1 ? (
                    <div className="ci-a-nop">{renderPaymentsFee()}</div>
                  ) : null}

                  {/* Submit button */}
                  <div className="ci-submit">
                    {paymentLink ? (
                      !paymentLink.paid ? (
                        <div
                          className="buttons"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <Button
                            type="dashed"
                            onClick={() => {
                              // copy payment url
                              navigator.clipboard.writeText(paymentLink.url);
                              message.success("קישור לתשלום הועתק בהצלחה");
                            }}
                            children="העתק קישור לתשלום"
                          />
                          <Button
                            type="primary"
                            onClick={() => {
                              // Open payment url in new tab
                              setPaymentLinkOpen(true);
                            }}
                            children="שלם עכשיו"
                            disabled={paymentLink.paid}
                          />
                        </div>
                      ) : (
                        <label
                          style={{
                            color: "green",
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {t("paymentagent.payment-done")}
                        </label>
                      )
                    ) : !submitLoading ? (
                      <button
                        type="submit"
                        className="credit-card-checkout-btn"
                      >
                        {t("paymentagent.create-pay-link")}
                      </button>
                    ) : (
                      <CustomLoading
                        classLoading={"credit-card-checkout-btn-loading"}
                        labelLoading={t("paymentagent.create-pay-link")}
                      />
                    )}
                  </div>

                  {/* Pics */}
                  <div className="ci-pics">
                    <div className="payment-agent-icons-wrapper">
                      <img
                        src={MasterCardIcon}
                        className="payment-agent-icons"
                        alt="Master Card Icon"
                      ></img>
                      <img
                        src={AmericanExpressIcon}
                        className="payment-agent-icons"
                        alt="American Express icon"
                      ></img>
                      <img
                        src={visa1Icon}
                        className="payment-agent-icons"
                        alt="visa icon"
                      ></img>
                      <img
                        src={visaIcon}
                        className="payment-agent-icons"
                        alt="visa icon"
                      ></img>
                    </div>

                    <label className="ci-pics-title">תשלום מאובטח</label>
                  </div>

                  {/* Error / Success messege */}
                  <div className="ci-messeger">
                    <label
                      id={`messege-${cardNumber}-suc`}
                      className="ci-messeger-success"
                    >
                      כרטיס האשראי עבר בהצלחה
                    </label>
                    <label
                      id={`messege-${cardNumber}-err`}
                      className="ci-messeger-error"
                    >
                      כרטיס האשראי לא עבר
                    </label>
                  </div>
                </form>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default CreditCardDetails;
