import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoading from "../../../../GeneralComponents/CustomLoading/customloading";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import "./passangerdata.css";
import NotificationPass from "./subComponents/Notifications/notification";
import RoomPassInfo from "./subComponents/RoomPassInfo/roompassinfo";
import { convertPrice } from "../../../../../services/currencyService";
import {
  bookHotel,
  cancellationPolicies,
  newHotelOrder,
} from "../../../../../services/fhpService";
import CustomAlertAgent from "../../../../GeneralComponents/CustomAlertAgent/customalertagent";
import TermsAndConditions from "../../../../../Pages/TermsAndConditions/TermsAndConditions";
import { useSearch } from "../../../../../contexts/SearchContext";
import { Loader } from "../../../../Loader/Loader";
import { useOrder } from "../../../../../contexts/OrderContext";
import { useAppContext } from "../../../../../contexts/AppContext";
import { message, notification } from "antd";

const errorCodes = {
  E20000: "קלט משתמש שגוי: שגיאה במידע הלקוח",
  E20001: "קלט משתמש שגוי: שגיאה במידע כרטיס האשראי",
  E20099: "קלט משתמש שגוי: שגיאה כללית בקלט",
  E20225: "לא ניתן לאשר את ההזמנה עקב פרטי CVV שגויים",
  E20221: "לא ניתן לאשר את ההזמנה עקב מחיר שגוי שצויין בבקשה",
  E3000: "התשלום לא אושר.",
  E3001: "התשלום לא הושלם.",
  E3002: "חיוב עמלת שירות אינו מותר על חשבון שער תשלום עם 3D מאופשר.",
  E3100: "סוג כרטיס האשראי אינו מותר.",
  E3101: "הקטע כבר סומן כמשולם.",
  E20100:
    "למשתמש אין הרשאה להזמין תעריפים נט שאינם ניתנים להחזר. יש לבדוק את הגדרות המשתמש.",
  E20101:
    "למשתמש אין מספיק אשראי לבצע את ההזמנה. אשראי נוכחי: {X} {X}, אשראי נדרש: {X} {X}. יש לבדוק את הגדרות האשראי.",
  E20102: "מחיר ההזמנה חורג מהטווח המותר.",
  E20103: "אין הרשאה לבצע את ההזמנה בחשבון זה.",
  E20104: "הזמנה מושבתת לאשכול '{X}'. יש לפנות לתמיכה.",
  E20105: "הזמנה מושבתת עבור סוג השירות '{X}'. יש לפנות לתמיכה.",
  E20106: "הזמנה מושבתת עבור זרימת ההזמנה '{X}'. יש לפנות לתמיכה.",
  E20107: "למשתמש '{0}' אין הרשאה לבצע את הפעולה '{1}'.",
  E20108:
    "ה'ChargeAmount' שצוין: '{0}' אינו חוקי. הערך חייב להיות גדול מ-0 ופחות או שווה למחיר ההזמנה.",
  E20109: "לא ניתן לחייב עמלת שירות.",
  E20110: "אמצעי התשלום אינו זמין.",
  E20111: "ערך ההנחה אינו חוקי.",
  E20199: "שגיאה כללית בקונפיגורציה",
  E20300: "לא ניתן לאשר את ההזמנה עקב שינוי מחיר.",
  E20301: "לא ניתן לאשר את ההזמנה עקב שינוי מטבע המחיר.",
  E20302: "לא ניתן לאשר את ההזמנה עקב שינוי במדיניות הביטול.",
  E20303: "לא ניתן לאשר את ההזמנה עקב שינוי במטבע עמלת הביטול.",
  E20304: "לא ניתן לאשר את ההזמנה עקב חוסר זמינות.",
  E20305: "לא התקבלה תגובת טרום-הזמנה.",
  E20306: "הבקשה חרגה ממגבלת הזמן. ייתכן שההזמנה בוצעה.",
  E20307: "לא ניתן להזמין פריט שכבר הוזמן באותה סשן.",
  E20308: "חיוב עמלת שירות אינו מותר על חשבון שער תשלום עם 3D מאופשר.",
  E20395: "לא התקבלה תגובת הזמנה.",
  E20396: "התקבלה תגובת הזמנה בלתי צפויה.",
  E20397: "התקבלה שגיאת תגובת הזמנה בלתי צפויה.",
  E20398: "שגיאת הזמנה בלתי צפויה.",
  E20200: "לא ניתן לאשר את ההזמנה עקב שינוי מחיר.",
  E20201: "לא ניתן לאשר את ההזמנה עקב שינוי מטבע המחיר.",
  E20202: "לא ניתן לאשר את ההזמנה עקב שינוי במדיניות הביטול.",
  E20203: "לא ניתן לאשר את ההזמנה עקב שינוי במדיניות הביטול.",
  E20204: "לא ניתן לאשר את ההזמנה עקב שינוי במטבע עמלת הביטול.",
  E20205: "לא ניתן לאשר את ההזמנה עקב שינוי בתאריכי מדיניות הביטול.",
  E20206: "לא ניתן לאשר את ההזמנה עקב תעריף שפג תוקפו.",
  E20207: "לא ניתן לאשר את ההזמנה עקב חוסר זמינות.",
  E20208: "לא ניתן לאשר את ההזמנה עקב שגיאת ספק צד שלישי.",
  E20209: "לא ניתן לאשר את ההזמנה עקב שגיאות תקשורת.",
  E20210: "לא ניתן לאשר את ההזמנה עקב שגיאת שם נוסע.",
  E20211: 'לא ניתן לאשר את ההזמנה עקב שגיאת דוא"ל נוסע.',
  E20212: "לא ניתן לאשר את ההזמנה עקב הזמנה כפולה.",
  E20213: "לא ניתן לאשר את ההזמנה עקב שגיאת קו אשראי.",
  E20214: "לא ניתן לאשר את ההזמנה עקב שגיאת הרשאות.",
  E20215: "לא ניתן לאשר את ההזמנה עקב שגיאה כללית באישור כרטיס אשראי.",
  E20216: "לא ניתן לאשר את ההזמנה עקב שגיאת אישור כרטיס אשראי.",
  E20217: "לא ניתן לאשר את ההזמנה עקב פרטי כרטיס אשראי שגויים.",
  E20218: "לא ניתן לאשר את ההזמנה עקב סוג כרטיס אשראי שאינו נתמך.",
  E20219: "לא ניתן לאשר את ההזמנה עקב מגבלת אזרחות.",
  E20220: "לא ניתן לאשר את ההזמנה עקב סטטוס הזמנה שנדחה.",
  E20297: "לא ניתן לאשר את ההזמנה עקב שגיאה כללית בבקשה.",
  E20298: "לא ניתן לאשר את ההזמנה עקב שגיאה כללית.",
  E20299: "שגיאת ספק בלתי ניתנת לטיפול.",
  E0400: "לא ניתן לבצע את הפעולה '{0}' על קטע במצב {1}",
  E0500: "שגיאה פנימית.",
  E0501: "הבקשה {0}.{1} אינה קיימת.",
  E0502: "הפעולה של הספק אינה ממומשת",
  E0999: "שגיאה לא ידועה.",
  E1000: "שגיאת ספק.",
  E2000: "למשתמש '{0}' אין הרשאה לבצע את הפעולה '{1}'.",
  E2001: "גישה למשתמש נדחתה.",
  E2002: "הגעת למגבלת המשאבים.",
  E2060: "אישורים לא צוינו.",
  E2061: "לא ניתן לאמת את האישורים.",
  E2062: "אין אפשרות לספק גם אישורים וגם מזהה סשן.",
  E2063: "כרטיס האשראי חשוד (לא עבר בדיקת הונאה)",
  E2300: "הקטע לא נמצא",
  E2301: "אי התאמה בין מזהי הזמנה",
  I0200: "הפעולה הצליחה.",
  I0404: "לא נמצאו תוצאות. שם ספק: {0}, מזהה חוזה: {1}.",
  I0405: "הבקשה אינה נתמכת. שם ספק: {0}, מזהה חוזה: {1}.",
  I0600: "שם נוסע כפול. תתבצע התאמת שם ופעולת ההזמנה תחודש.",
};

const PassangerData = () => {
  // Translator
  const { getAgentCommissionPercent } = useAppContext();
  const { currentHotelOrder, setCurrentHotelOrder, removeCurrentHotelOrder } =
    useOrder();
  const { t } = useTranslation();
  const { passengerInfo, setPassengerInfo } = useSearch();
  // Navigator
  const navigate = useNavigate();
  const modalRef = React.createRef();
  const inputRef = React.createRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) // Check if click is not on the model
      ) {
        setIsPopCancelationConditions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, modalRef]);

  // Constats
  const Rooms = currentHotelOrder.searchPhrase.rooms; // Rooms & passengers ( Adults and Kids)

  let RoomData = [];

  if (Rooms) {
    const numberOfRooms = Rooms.length;
    const adultsCountPerRoom = Rooms.map((room) => room.AdultsCount); //Amount of adults minus the two existing ones
    const kidsCountPerRoom = Rooms.map((room) =>
      room.KidsAges ? room.KidsAges.length : 0
    );

    // Create for each room basic stractre with the right amount of adults and kids
    for (let i = 0; i < numberOfRooms; i++) {
      let roomArray = [];

      // Add 2 Main adults (For the first room Create a 1 Contact Person)
      if (i === 0) {
        roomArray.push(
          {
            firstname: null,
            lastname: null,
            cell: null,
            email: null,
            isKid: false,
          },
          { firstname: null, lastname: null, isKid: false }
        );
      } else {
        roomArray.push(
          { firstname: null, lastname: null, isKid: false },
          { firstname: null, lastname: null, isKid: false }
        );
      }

      // Add additonal adults
      for (let j = 0; j < adultsCountPerRoom[i] - 2; j++) {
        roomArray.push({ firstname: null, lastname: null, isKid: false });
      }

      // Add kids
      for (let k = 0; k < kidsCountPerRoom[i]; k++) {
        roomArray.push({ firstname: null, lastname: null, isKid: true });
      }

      RoomData.push(roomArray);
    }
  }

  const initialPassangerInfo = RoomData;

  // Create an array of RoomData objects repeated Rooms.length times
  const [specialReq, setSpecialReq] = useState("");
  const [isConditionsConfirmed, setIsConditionsConfirmed] = useState(false);
  const [isCancelHotel, setIsCancelHotel] = useState(false);
  const [dataCancelHotel, setDataCancelHotel] = useState([]);
  const [dataCancelHotelRemarks, setDataCancelHotelRemarks] = useState([]);
  const [isCancelFlight, setIsCancelFlight] = useState(false);
  const [dataCancelFlight, setDataCancelFlight] = useState([]);
  const [isPopCancelationConditions, setIsPopCancelationConditions] =
    useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitLoadingPayment, setSubmitLoadingPayment] = useState(false);

  useEffect(() => {
    !passengerInfo && setPassengerInfo(initialPassangerInfo);
  }, [passengerInfo]);

  // Useeffect - get cancalation policys
  useEffect(() => {
    const getCancellationPolicies = async () => {
      const data = {
        session: currentHotelOrder.searchPhrase.session,
        hotelID: parseInt(currentHotelOrder.searchPhrase.hotelID),
        packageID: currentHotelOrder.selectedHotelPackage.packageID,
      };

      const res = await cancellationPolicies(data);
      if (!res.data) {
        return;
      }

      setDataCancelHotel(res.data.policies);

      setDataCancelHotelRemarks(res.data.remarks);
    };

    if (
      currentHotelOrder &&
      dataCancelHotel &&
      (!dataCancelHotel.length || !dataCancelHotelRemarks.length)
    ) {
      getCancellationPolicies();
    }
  }, [currentHotelOrder]);

  // --- Functions
  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitLoading || submitLoadingPayment) {
      return;
    }
    if (!isConditionsConfirmed) {
      CustomAlertAgent(t("warnings.approveconditions"), t("warnings.goback"));
      return;
    }
    if (currentHotelOrder) {
      // Set loading on
      setSubmitLoadingPayment(true);
      // setIsConditionsConfirmed(true);
      disableOtherSubmitBtn("submit2", true);

      // Add passenger data
      currentHotelOrder["passangerData"] = {
        passengerInfo: passengerInfo,
        specialReq: specialReq,
      };

      // Save the updates
      setCurrentHotelOrder(currentHotelOrder);

      const gatherBookingData = () => {
        // Get Data
        const loggedUser = JSON.parse(localStorage.getItem("UserInfo"));
        const clientData = JSON.parse(
          localStorage.getItem("agent-saved-client-info")
        );

        const { selectedRoom } = currentHotelOrder;

        // Arrange passsangers
        const passangerData = currentHotelOrder.passangerData.passengerInfo;

        let alteredPassangerDataAdults = [];
        let alteredPassangerDataKids = [];

        passangerData.forEach((room) => {
          room.forEach((passenger) => {
            let PersonDetails = {
              PersonDetails: {
                Name: {
                  GivenName: passenger.firstname,
                  NamePrefix: passenger.gender || "Mr",
                  Surname: passenger.lastname,
                },
              },
            };

            if (!passenger.isKid) {
              alteredPassangerDataAdults.push(PersonDetails);
            } else {
              alteredPassangerDataKids.push(PersonDetails);
            }
          });
        });

        const alteredPassangerData = [
          ...alteredPassangerDataAdults,
          ...alteredPassangerDataKids,
        ];

        const currentRoom = currentHotelOrder.selectedHotel.Result.find(
          (r) =>
            r.PackageId === currentHotelOrder.selectedHotelPackage.packageID
        );

        let data = {
          session: currentHotelOrder.searchPhrase.session,
          checkIn: String(currentHotelOrder.searchPhrase.checkIn),
          checkOut: String(currentHotelOrder.searchPhrase.checkOut),
          package: { ...currentRoom, images: selectedRoom?.images },
          passengers: alteredPassangerData,
          commission: currentHotelOrder.ourPrice.hrCommission,
          agentID: loggedUser.id,
          clientID: -1,
          fullname: clientData.fullname,
          cell: clientData.phone,
          email: clientData.email,
          hotel: currentHotelOrder.selectedHotel,
          paid: false,
          remarks: currentHotelOrder.passangerData.specialReq,
          includesTaxes: currentHotelOrder.selectedHotelPackage.includesTaxes,
          handleFee: currentHotelOrder.ourPrice?.handleFeeData?.value || 0,
          handleFeeOption:
            currentHotelOrder.ourPrice?.handleFeeData?.type || "+",
          totalPrice:
            currentHotelOrder.ourPrice.priceWithCreditCardFee.toFixed(2),
          cardFee: currentHotelOrder.ourPrice.creditCardFee.toFixed(2),
          cards: [],
        };
        return data;
      };

      const setCurrentOrderId = (orderId) => {
        localStorage.removeItem("agent-saved-client-info");
        navigate("/p/dashboard/payment/" + orderId);
      };

      const bookInfo = gatherBookingData();

      // New order payment
      // console.dir(bookInfo);
      newHotelOrder(bookInfo)
        .then((res) => {
          const orderId = res.data.id;
          if (orderId) setCurrentOrderId(orderId);
          else if (res.data.status === "fail" && res.data.code) {
            const code = res.data.code;
            if (errorCodes[code]) {
              notification.error({
                message: "שגיאה בהזמנת המלון",
                description: errorCodes[code],
                placement: "bottomRight",
                duration: 5,
              });
            } else {
              notification.error({
                message: "שגיאה בהזמנת המלון",
                description: "שגיאה לא ידועה",
                placement: "bottomRight",
                duration: 5,
              });
            }
          }
          setSubmitLoading(false);

          // Set loading on
          setSubmitLoadingPayment(false);
          // setIsConditionsConfirmed(false);
          disableOtherSubmitBtn("submit2", false);
        })
        .catch((err) => {
          message.error("שגיאה בהזמנת המלון");
          // Set loading on
          setSubmitLoadingPayment(false);
          // setIsConditionsConfirmed(false);
          disableOtherSubmitBtn("submit2", false);
        });
    } else {
      CustomAlertAgent(t("warnings.somthingwentwrong"), t("warnings.tryagain"));
    }
  };

  // Triggers
  const showMoreHotelCancelation = () => {
    setIsCancelHotel(!isCancelHotel);
  };

  const showMoreFlightCancelation = () => {
    setIsCancelFlight(!isCancelFlight);
  };

  const openCancelationConditions = () => {
    setIsPopCancelationConditions(!isPopCancelationConditions);
  };

  // For pay later check if all inputs were answered
  const checkRequiredInputs = () => {
    // Check if all required inputs are filled
    const requiredInputs = document.querySelectorAll(
      ".passangerData-component input"
    );

    for (const input of requiredInputs) {
      // Check if it's a checkbox and is checked, or if it's a text input and has a value
      if (input.type === "checkbox" && !input.checked) {
        CustomAlertAgent(t("warnings.fillallinputs"), t("warnings.goback"));
        return false;
      } else if (input.type === "text" && input.value.trim() === "") {
        CustomAlertAgent(t("warnings.fillallinputs"), t("warnings.goback"));
        return false;
      }
    }
    return true;
  };

  // For new order
  const bookWithOutPay = async (e) => {
    e.preventDefault();

    if (submitLoading || submitLoadingPayment) {
      return;
    }

    const gatherPayMentData = (paid) => {
      // Get Data
      const loggedUser = JSON.parse(localStorage.getItem("UserInfo"));
      const clientData = JSON.parse(
        localStorage.getItem("agent-saved-client-info")
      );
      const { selectedRoom } = currentHotelOrder;

      // Arrange passsangers
      const passangerData = passengerInfo;

      let alteredPassangerDataAdults = [];
      let alteredPassangerDataKids = [];

      passangerData.forEach((room) => {
        room.forEach((passenger) => {
          let PersonDetails = {
            PersonDetails: {
              Name: {
                GivenName: passenger.firstname,
                NamePrefix: passenger.gender || "Mr",
                Surname: passenger.lastname,
              },
            },
          };

          if (!passenger.isKid) {
            alteredPassangerDataAdults.push(PersonDetails);
          } else {
            alteredPassangerDataKids.push(PersonDetails);
          }
        });
      });

      const alteredPassangerData = [
        ...alteredPassangerDataAdults,
        ...alteredPassangerDataKids,
      ];

      const currentRoom = currentHotelOrder.selectedHotel.Result.find(
        (r) => r.PackageId === currentHotelOrder.selectedHotelPackage.packageID
      );

      let data = {
        session: currentHotelOrder.searchPhrase.session,
        checkIn: String(currentHotelOrder.searchPhrase.checkIn),
        checkOut: String(currentHotelOrder.searchPhrase.checkOut),
        package: { ...currentRoom, images: selectedRoom?.images },
        passengers: alteredPassangerData,
        commission: getAgentCommissionPercent(),
        agentID: loggedUser.id, // ?
        clientID: -1, // ?
        fullname: clientData.fullname,
        cell: clientData.phone,
        email: clientData.email,
        hotel: currentHotelOrder.selectedHotel,
        paid: paid,
        remarks: specialReq,
        includesTaxes: currentHotelOrder.selectedHotelPackage.includesTaxes,
        handleFee: currentHotelOrder.ourPrice?.handleFeeData?.value || 0,
        handleFeeOption: currentHotelOrder.ourPrice?.handleFeeData?.type || "+",
        totalPrice: currentHotelOrder.ourPrice.priceWithCreditCardFee,
        cardFee: 0, //cardFee,
        cards: [],
        payments: [],
        ourPrice: currentHotelOrder.ourPrice,
      };

      return data;
    };

    // Check required inputs
    if (!checkRequiredInputs()) {
      return;
    }

    // Set loading on
    setSubmitLoading(true);
    disableOtherSubmitBtn("submit1", true);

    const data = gatherPayMentData(false); // false stands for pay later

    // Book hotel
    const res = await bookHotel(data);

    if (res.data && res.data.status === "ok") {
      // Reset Order
      removeCurrentHotelOrder();
      localStorage.removeItem("agent-saved-client-info");

      navigate("/p/dashboard/success/" + res.data.id);
    } else {
      const code = res.data.code;
      if (errorCodes[code]) {
        notification.error({
          message: "שגיאה בהזמנת המלון",
          description: errorCodes[code],
          placement: "bottomRight",
          duration: 5,
        });
      } else {
        notification.error({
          message: "שגיאה בהזמנת המלון",
          description: "שגיאה לא ידועה",
          placement: "bottomRight",
          duration: 5,
        });
      }
    }

    // Set loading off
    setSubmitLoading(false);
    disableOtherSubmitBtn("submit1", false);
  };

  const disableOtherSubmitBtn = (idOfButton, state) => {
    const button = document.getElementById(idOfButton);

    if (button) {
      button.disabled = state;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="passangerData-component">
      {/* Notification - For hotel Choosing */}
      <div className="tw-py-2">
        <NotificationPass
          title={t("completepassengers.greatchoice")}
          subtitle={t("completepassengers.greatchoicetext")}
        />
      </div>

      {/* Room Displayer */}
      {!passengerInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="passangerData-room-pass-info-wrapper">
          {Rooms &&
            Rooms.map((room, index) => (
              <RoomPassInfo
                key={index}
                roomInfo={room}
                roomNumber={index}
                roomName={
                  currentHotelOrder.selectedHotelPackage.roomNames[index]
                }
              />
            ))}
        </div>
      )}

      {/* Special Requests */}
      <div className="passangerData-special-requests-wrapper">
        <label className="passangerData-special-requests-title">
          {t("completepassengers.specialrequests")}
        </label>

        <textarea
          onChange={(e) => {
            setSpecialReq(e.target.value);
          }}
          className="passangerData-special-requests-textarea"
          rows="4"
          cols="100%"
          placeholder={t("completepassengers.specialrequeststext")}
        ></textarea>
      </div>

      {/* Cancelation Policy - Hotel */}
      <div className={`passangerData-cancelation-policy-wrapper`}>
        <label className="passangerData-cancelation-policy-title">
          {t("completepassengers.policycanclationhotel")}
        </label>

        <label className="passangerData-cancelation-policy-sub-title">
          {currentHotelOrder.selectedHotel.HotelName}
        </label>

        <div
          className={`passangerData-cancelation-policy-cancel-wrapper  ${
            isCancelHotel ? "passangerData-cancelation-policy-wrapper-wide" : ""
          }`}
        >
          <div className="passangerData-cancelation-policy-cancel-heading-wrapper">
            {currentHotelOrder.selectedHotelPackage.refundability ? (
              <label className="passangerData-cancelation-policy-cancel-title">
                {t("completepassengers.freecancelation")}{" "}
                {currentHotelOrder.selectedHotelPackage.refundableUntil}
              </label>
            ) : (
              <>
                <label className="passangerData-cancelation-policy-cancel-title-not-free">
                  {t("completepassengers.nofreecancelation")}
                  <span style={{ marginRight: 15 }}>-</span>
                  <span style={{ marginRight: 15 }}>
                    {convertPrice(dataCancelHotel[0]?.fee)}
                  </span>
                </label>
              </>
            )}

            {!isCancelHotel && (
              <div
                onClick={showMoreHotelCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronDown className="assangerData-showmore-arrow"></BiChevronDown>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showmore")}
                </label>
              </div>
            )}
          </div>

          {dataCancelHotelRemarks && isCancelHotel && (
            <div className="passangerData-cancelation-entrys-button-wrapper">
              <div className="passangerData-cancelation-entrys-remark-wrapper">
                <div
                  className="passangerData-cancelation-policy-remarks"
                  dangerouslySetInnerHTML={{ __html: dataCancelHotelRemarks }}
                />

                {dataCancelHotel.length > 0 ? (
                  <div className="passangerData-cancelation-entrys">
                    {dataCancelHotel.map((cancelRowData, index) => (
                      <div key={index}>
                        <label className="passangerData-cancelation-entry">
                          {t("completepassengers.startsat")}
                          {cancelRowData.from} {t("completepassengers.feesare")}{" "}
                          {
                            <label id="price-fee">
                              {convertPrice(
                                currentHotelOrder.ourPrice
                                  .priceWithCreditCardFee
                              )}
                            </label>
                          }
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="passangerData-cancelation-empty">
                    No Canclation Policys Available
                  </div>
                )}
              </div>

              <div
                onClick={showMoreHotelCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronUp className="assangerData-showmore-arrow"></BiChevronUp>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showless")}
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Cancelation Policy - Flight */}
      {/* <div className="passangerData-cancelation-policy-wrapper">
        <label className="passangerData-cancelation-policy-title">
          {t("completepassengers.policycanclationflight")}
        </label>

        <label className="passangerData-cancelation-policy-sub-title">
          flight name...
        </label>

        <div
          className={`passangerData-cancelation-policy-cancel-wrapper  ${
            isCancelFlight
              ? "passangerData-cancelation-policy-wrapper-wide"
              : ""
          }`}
        >
          <div className="passangerData-cancelation-policy-cancel-heading-wrapper">
            <label className="passangerData-cancelation-policy-cancel-title">
              {t("completepassengers.freecancelation")}
            </label>

            {!isCancelFlight && (
              <div
                onClick={showMoreFlightCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronDown className="assangerData-showmore-arrow"></BiChevronDown>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showmore")}
                </label>
              </div>
            )}
          </div>

          {isCancelFlight && (
            <div className="passangerData-cancelation-entrys-button-wrapper">
              {dataCancelFlight.length > 0 ? (
                <div className="passangerData-cancelation-entrys">
                  {dataCancelFlight.map((cancelRowData, index) => (
                    <div key={index}>
                      <label className="passangerData-cancelation-entry">
                        {t("completepassengers.startsat")} {cancelRowData.from}{" "}
                        {t("completepassengers.startsat")} {cancelRowData.to}{" "}
                        {t("completepassengers.feesare")}{" "}
                        {
                          <label id="price-fee">
                            {convertPrice(cancelRowData.fee)}
                          </label>
                        }
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="passangerData-cancelation-empty">
                  No Canclation Policys Available
                </div>
              )}

              <div
                onClick={showMoreFlightCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronUp className="assangerData-showmore-arrow"></BiChevronUp>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showless")}
                </label>
              </div>
            </div>
          )}
        </div>
      </div> */}

      {/* Payment */}
      <div className="passangerData-checkout-wrapper">
        <div className="passangerData-checkout-checkbox-wrapper">
          <input
            type="checkbox"
            className="passangerData-checkout-checkbox"
            required
            onChange={(e) => setIsConditionsConfirmed(e.target.checked)}
          ></input>
          <span className="passangerData-checkout-checkbox-text-black">
            {`${t("completepassengers.approve")}`}
          </span>
          <span
            className="passangerData-checkout-checkbox-text"
            onClick={openCancelationConditions}
          >
            {t("completepassengers.confirmconditions")}
          </span>

          {isPopCancelationConditions ? (
            <div className="passangerData-popup-wrapper">
              <div ref={modalRef} className="passangerData-popup-frame">
                <TermsAndConditions />

                <div className="passangerData-popup-agree-btn-wrapper">
                  <button
                    type="button"
                    className="passangerData-popup-agree-btn"
                    onClick={openCancelationConditions}
                  >
                    {t("completepassengers.close")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="passangerData-checkout-button-wrapper">
          <div className="passangerData-submit-button-wrapper">
            {!submitLoadingPayment ? (
              <button
                type="submit"
                id="submit1"
                className={
                  !isConditionsConfirmed
                    ? "button-disabled"
                    : "passangerData-checkout-button"
                }
                disabled={!isConditionsConfirmed}
              >
                {t("completepassengers.pay")}
              </button>
            ) : (
              <CustomLoading
                classLoading={""}
                labelLoading={t("completepassengers.pay")}
              />
            )}
          </div>

          <div className="passangerData-submit-button-wrapper">
            {currentHotelOrder.selectedHotelPackage.refundability &&
              (!submitLoading ? (
                <button
                  type="button"
                  id="submit2"
                  className={
                    !isConditionsConfirmed
                      ? "button-disabled"
                      : "passangerData-submit-button-pay-later"
                  }
                  onClick={bookWithOutPay}
                  disabled={!isConditionsConfirmed}
                >
                  {t("completepassengers.paylater")}
                </button>
              ) : (
                <CustomLoading
                  classLoading={""}
                  labelLoading={t("completepassengers.paylater")}
                />
              ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default PassangerData;

//{
//     "adults": [
//         {
//             "firstname": "sfdsdf",
//             "lastname": null,
//             "cell": "",
//             "email": ""
//         },
//         {
//             "firstname": "sfdsdf",
//             "lastname": null,
//             "cell": "",
//             "email": ""
//         }
//     ],
//     "kids": []
// }
