import React, { useState } from "react";
// import { useNavigate } from 'react-router-dom';
import ReactLoading from "react-loading";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./pricing.css";

// Services
// import { getHotelsProfileData } from '../../../../services/fhpService';
import { convertPrice } from "../../../../services/currencyService";
import { parsePriceToShow } from "../../../../utils/OrderService";
import { SubTitle, Title } from "../../../Text/Text";
import { useNavigate } from "react-router-dom";

const Pricing = ({ item, searchPhrase, searchSession }) => {
  // Translator
  const { t } = useTranslation();
  const navigate = useNavigate();

  // States
  const [isLoading, setIsLoading] = useState(false);

  // --- Functions

  const navigaeToHotelsProfile = async (e) => {
    e.preventDefault();

    // Gather data
    let data = {
      hotelID: item.ID,
      session: searchSession,
      checkIn: searchPhrase.checkIn,
      checkOut: searchPhrase.checkOut,
      rooms: searchPhrase.rooms,
    };

    // Save in the local storage the new data
    localStorage.setItem("currentSearchPhrase", JSON.stringify(data));

    // Open the link in a new tab
    navigate("/hotelprofile/" + item.ID);
  };

  return (
    <>
      {/* Pricing */}
      <div className="pricing-wrapper">
        {/* Loading Screen */}
        {isLoading && (
          <div className="loading-screen">
            <ReactLoading type="spinningBubbles" color="#ff3703" width={65} />
          </div>
        )}

        {/* Content */}
        <div className="pricing-price-for-wrapper">
          {/* Price For Agent */}
          <div className="pricing-pricing-agent-wrapper">
            <SubTitle className="pricing-pricing-travler-title">
              {t("agentpricing.priceforagent")}
            </SubTitle>
            <Title
              id="price-package"
              className=" lg:tw-text-[20px] tw-text-[#0258a4] tw-font-bold"
              // origPrice={item?.LowestPackagePrice || 0}
            >
              {convertPrice(
                parsePriceToShow(
                  item.ourPrice?.priceWithCommissionAndCreditCardFee || 0
                )
              )}
            </Title>
          </div>
        </div>

        <div className="pricing-buttons-wrapper">
          <button
            onClick={navigaeToHotelsProfile}
            className="pricing-button pricing-button-orange"
          >
            {t("agentpricing.chooserooms")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Pricing;
