import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Sidenav from "../Components/SideNav/SideNav";
import Orders from "../Components/Orders/Orders";
import Login from "../Pages/Client/Login/login";
import Finance from "../Components/Finance/Finance";
import HotelProfile from "../Pages/Client/HotelProfile/hotelprofile";
import SearchAgent from "../Pages/Agent/SearchAgent/searchagent";
import PriceOffer from "../Pages/Agent/PriceOffer/priceoffer";
import UpperNavBarClient from "../Components/Client/UpperNavBarClient/uppernavbarclient";
import UpperNavBarAgent from "../Components/Agent/NavBarAgent/UpperNavBarAgent/uppernavbaragent";
import PaymentAgent from "../Components/Agent/PaymentAgent/paymentagent";
import SuccessOrder from "../Components/Agent/PaymentAgent/SuccessOrder/successorder";
import { isAuthenticated, getUserDetails } from "../services/authService";
import { getSideBarStatus } from "../services/localStorage";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import Registration from "../Components/Registration/Registration";
import { RegistrationProvider } from "../contexts/RegistrationContext";
import { Loader } from "../Components/Loader/Loader";
import Main from "../Pages/Main/MainPage";
import Admin from "../Pages/Admin/Admin";
import AgentTerms from "../Pages/AgentTerms/AgentTerms";
import PackagesProfile from "../Pages/Client/PackagesProfile/PackagesProfile";
import SavedPackagesProfile from "../Pages/Client/PackagesProfile/SavedPackageProfile";
import UnsavedPackagesProfile from "../Pages/Client/PackagesProfile/UnsavedPackagesProfile";
import SavedToursProfile from "../Pages/Client/OrganizedTourProfile/SavedToursProfile";
import UnsavedToursProfile from "../Pages/Client/OrganizedTourProfile/UnsavedToursProfile";
import PackageOrderSummary from "../Components/Agent/SearchAgent/PackageObject/PackageOrderSummary";
import Management from "../Pages/Management/Management";
import { LoaderModal } from "../Components/Loader/LoaderModal";
import { useAppContext } from "../contexts/AppContext";
import { FaBars } from "react-icons/fa";
import OrganizedTourProfile from "../Pages/Client/OrganizedTourProfile/OrganizedTourProfile";

export const ROUTES = {
  TERMS_AND_CONDITIONS: "termsAndConditions",
  AGENT_TERMS: "agentTerms",
  LOGIN: "login",
  MAIN: "",
  PACKAGE_PROFILE: "packages/profile",
  ORDER_SUCCESS: "packages/success",
  TOURE_PROFILE: "tour/profile",
  TOUR_SUCCESS: "tour/success",
};

const InnerRoutes = () => {
  const NotFound = () => {
    return (
      <div>
        <h1>שים לב!</h1>
        <p>הדף כרגע בבניה</p>
      </div>
    );
  };

  const PublicClientRoutes = () => {
    const [showNavBar, setShowNavBar] = useState(true);
    const { isAppLoggedIn } = useAppContext();
    const location = useLocation();
    useEffect(() => {
      if (!isAppLoggedIn() && location.pathname !== "/") {
        setShowNavBar(false);
      }
    }, []);

    return (
      <div className={"client-pages " + location.pathname.split("/").pop()}>
        {showNavBar && <UpperNavBarClient />}

        <div className="client-component">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="login" element={<Login />} />
            <Route
              path="registeragent"
              element={
                <RegistrationProvider>
                  <Registration />
                </RegistrationProvider>
              }
            />
            {/*<Route path="registeragent" element={<RegisterAgent />} />*/}
            <Route
              path={`/${ROUTES.TERMS_AND_CONDITIONS}`}
              element={
                <div
                  style={{
                    height: "100vh",
                    overflow: "auto",
                    backgroundColor: "white",
                  }}
                >
                  <TermsAndConditions />
                </div>
              }
            />
            <Route path={`/${ROUTES.AGENT_TERMS}`} element={<AgentTerms />} />
            <Route path="hotelprofile/:hotelid" element={<HotelProfile />} />
            <Route
              path={`${ROUTES.PACKAGE_PROFILE}`}
              element={<UnsavedPackagesProfile />}
            />
            <Route
              path={`${ROUTES.PACKAGE_PROFILE}`}
              element={<PackagesProfile />}
            />
            <Route
              path={`${ROUTES.PACKAGE_PROFILE}/:id`}
              element={<SavedPackagesProfile />}
            />
            <Route
              path={`${ROUTES.TOURE_PROFILE}`}
              element={<UnsavedToursProfile />}
            />
            <Route
              path={`${ROUTES.TOURE_PROFILE}`}
              element={<OrganizedTourProfile />}
            />
            <Route
              path={`${ROUTES.TOURE_PROFILE}/:id`}
              element={<SavedToursProfile />}
            />
            <Route
              path={`${ROUTES.ORDER_SUCCESS}/:id`}
              element={<PackageOrderSummary />}
            />
            {/*<Route path={`Payment`} element={<Payment />} />*/}
            {/* Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    );
  };

  const SecureClientRoutes = () => {
    return (
      <div className="client-pages">
        <UpperNavBarClient />

        <div className="client-component">
          <Routes>
            <Route path="" element="site is under construction secure" />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    );
  };

  const AgentRoutes = () => {
    const navigate = useNavigate();
    const [showSlideBar, setShowSlideBar] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      if (window.innerWidth < 640) {
        setShowSlideBar(false);
        setIsMobile(true);
      }
    }, []);
    const [sideBarOpen, setSidebarOpen] = useState(getSideBarStatus());

    // CSS fixes on payment page
    useEffect(() => {
      const isPaymentPage = window.location.pathname.includes(
        "/p/dashboard/payment/"
      );

      if (!isPaymentPage) {
        // Remove classes starting with 'cie' from the agent component
        const removeCieClasses = () => {
          const allElements = document.querySelectorAll("*");

          allElements.forEach((element) => {
            const classes = element.classList;

            // Iterate through the classes and remove those starting with 'cie'
            classes.forEach((className) => {
              if (className.startsWith("cie")) {
                // Remove the class
                element.classList.remove(className);
              }
            });
          });
        };

        // Call the function on initial render
        removeCieClasses();
      }
    }, [navigate]);

    return (
      <div className="agent-pages tw-flex tw-flex-col tw-bg-[#f3f3f3] tw-min-h-full  tw-relative tw-w-full tw-overflow-hidden">
        <UpperNavBarAgent sideBarOpen={sideBarOpen} />
        <div className="agent-content">
          <Sidenav setSidebarOpen={setSidebarOpen} />
          <div className="agent-component">
            <Routes>
              <Route path="/home" element={<SearchAgent />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/finance" element={<Finance />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/management" element={<Management />} />
              <Route
                path="/searchoffer/:selectedObject"
                element={<PriceOffer />}
              />
              <Route path="/searchoffer" element={<PriceOffer />} />
              {/* <Route path="/customers" element={<Customers />} /> */}
              {/* <Route path="/reports" element={<Reports />} /> */}
              {/* <Route path="/academy" element={<Academy />} /> */}
              {/* <Route path="/finances" element={<Finances />} /> */}
              <Route
                path="/payment/:currentOrderId"
                element={<PaymentAgent />}
              />
              <Route
                path="/success/:currentOrderId"
                element={<SuccessOrder />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    );
  };

  const AdminRoutes = () => (
    <div className="admin-pages">
      <Routes>
        <Route path="countries" element="countries" />
        <Route path="tournaments" element="Tournaments" />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );

  const SecureRoutes = () => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState("");
    const { isAppLoggedIn } = useAppContext();

    // Get session state
    useEffect(() => {
      if (isAppLoggedIn()) {
        setAuthenticated(true);
      } else {
        navigate(`/${ROUTES.MAIN}`);
      }
    }, [navigate]);

    // Get authonticated user type
    useEffect(() => {
      setAuthenticatedUser(getUserDetails());
    }, []);

    if (authenticated) {
      if (authenticatedUser.usertype === "clients") {
        return (
          <div className="public-secure-routes">
            <Routes>
              {/* Client Secure Routes */}
              <Route path="/*" element={<SecureClientRoutes />} />
            </Routes>
          </div>
        );
      } else if (authenticatedUser.usertype === "agents") {
        return (
          <div className="public-secure-routes">
            <Routes>
              {/* Agent Secure Routes */}
              <Route path="/dashboard/*" element={<AgentRoutes />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        );
      } else if (authenticatedUser.usertype === "admins") {
        return (
          <div className="public-secure-routes">
            <Routes>
              {/* Admin Secure Routes */}
              <Route path="/administration/*" element={<AdminRoutes />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        );
      } else {
        // pass
      }
    }

    // Render loading or placeholder content while the authentication check is in progress
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoaderModal />
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/*" element={<PublicClientRoutes />} />

        {/* Secure Routes */}
        <Route path="/p/*" element={<SecureRoutes />} />
      </Routes>
    </Router>
  );
};

export default InnerRoutes;
