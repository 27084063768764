import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./splitpayment.css";

// Components
import CreditCardDetails from "./subComponent/CreditCardDetails/creditcarddetails";

// Services
import { EMPTY_CARD, useCards } from "../../../../contexts/CardsContext";
import TotalPayment from "../TotalPayment";
import { useOrder } from "../../../../contexts/OrderContext";
import { message, notification } from "antd";

const SplitPayment = ({
  orderReqData,
  setOrderReqData,
  packageId,
  handlingFee,
  baseCommissions,
  cardFee,
  isNewOrder,
  sumPaid = 0,
  getOrderData,
}) => {
  const { currentHotelOrder } = useOrder();

  // Translator
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cards, updateCards, allCardsInterest } = useCards();
  const [paymentLinks, setPaymentLinks] = useState([]);

  // States

  const [priceToPay, setPriceToPay] = useState(
    parseFloat(currentHotelOrder.ourPrice.priceWithCreditCardFee - sumPaid)
  );

  const [paymentLinksTotalToPay, setPaymentLinksTotalToPay] = useState(0);

  // Update the totel price state when loaded
  useEffect(() => {
    setPriceToPay(
      parseFloat(currentHotelOrder.ourPrice.priceWithCreditCardFee)
    );
  }, [currentHotelOrder.ourPrice.priceWithCreditCardFee, allCardsInterest]);

  useEffect(() => {
    if (orderReqData) {
      if (orderReqData.payments) {
        setPaymentLinks(orderReqData.payments);
        setPaymentLinksTotalToPay(
          orderReqData.payments
            .filter((payment) => payment.paid === true)
            .reduce((acc, cur) => acc + cur.actualAmount, 0)
        );
        const tmpCards = cards;
        if (tmpCards.length < orderReqData.payments.length) {
          for (let i = tmpCards.length; i < orderReqData.payments.length; i++) {
            tmpCards.push({ ...EMPTY_CARD });
          }
          updateCards(tmpCards);
        }
      }
      if (
        orderReqData.errors &&
        orderReqData.errors.length > 0 &&
        orderReqData.status !== "OK"
      ) {
        for (let i = orderReqData.errors.length - 1; i > 0; i--) {
          const error = orderReqData.errors[i];
          notification.error({
            message: error.message || error.text,
            duration: 0,
            placement: "bottomLeft",
          });
        }
        notification.info({
          message: "ישנן שגיאות בסגירת המלון, יש ליצור קשר עם מחלקת אופרציה",
          duration: 0,
          placement: "bottomLeft",
        });
      } else if (orderReqData.paid)
        navigate("/p/dashboard/success/" + orderReqData.id);
    }
  }, [orderReqData]);

  const handleCCNumChange = (e) => {
    const newValue = parseInt(e.target.value);

    // Check if the entered value is greater than or equal to 1
    if (!isNaN(newValue) && newValue >= 1) {
      let tempCards = [];
      if (cards.length > newValue) {
        if (paymentLinks.length > newValue)
          return message.error(
            "לא ניתן להפחית את מספר הכרטיסים מתחת לכמות הקישורים לתשלום אשר נוצרו"
          );
        tempCards = cards.slice(0, newValue);
      } else {
        if (paymentLinksTotalToPay < priceToPay) {
          tempCards = cards;
          for (let i = cards.length; i < newValue; i++) {
            tempCards.push({ ...EMPTY_CARD });
          }
        } else return message.warning("לא נשאר יותר כסף לתשלום");
      }
      // tempCards[0].amount = currentHotelOrder.ourPrice.priceWithCreditCardFee;

      updateCards(tempCards);
    } else {
      // If the entered value is less than 1 or not a valid number, set it to 1
      updateCards([EMPTY_CARD]);
    }
  };

  return (
    <>
      <div className="splitPayment-wrapper splitPayment-sub-wrapper">
        {/* Set Credit Card Number */}
        <div className="splitPayment-card-amount-wrapper-wrapper">
          <div className="splitPayment-card-amount-wrapper lg:tw-flex lg:tw-w-[90%]">
            <div className="splitPayment-card-amount">
              <div className="splitPayment-card-amount-button">
                <label className="splitPayment-card-amount-title">
                  {t("paymentagent.setnumcard")}
                </label>
                <select
                  name="splitPayment-card-amount-input"
                  id="splitPayment-card-amount-input"
                  onChange={handleCCNumChange}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>
            <div className="flex-column">
              <div className="splitPayment-card-amount-totalprice">
                <label className="splitPayment-card-amount-price-title">
                  {t("paymentagent.priceis")}:
                  <span
                    id="priProblem-total"
                    className="splitPayment-card-amount-price-number"
                  >
                    <TotalPayment
                      totalPrice={
                        currentHotelOrder.ourPrice.priceWithCreditCardFee
                      }
                    />
                  </span>
                </label>
              </div>
              {isNewOrder && packageId && (
                <button
                  className="passangerData-submit-button-pay-later"
                  onClick={() => {
                    // Navigate to payment page
                    navigate("/p/dashboard/searchoffer/" + packageId);
                  }}
                >
                  {t("paymentagent.backToReserve")}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Credit Card Displayer */}
        <div className="splitPayment-card-displayer">
          {!priceToPay ? (
            <h1>Loading</h1>
          ) : (
            Array.from({ length: cards.length }, (_, index) => index + 1).map(
              (index) => (
                <div key={index}>
                  <CreditCardDetails
                    cardNumber={index}
                    cardIndex={index - 1}
                    orderReqData={orderReqData}
                    setOrderReqData={setOrderReqData}
                    paymentLink={
                      index - 1 < paymentLinks.length
                        ? paymentLinks[index - 1]
                        : null
                    }
                    handlingFee={handlingFee}
                    baseCommissions={baseCommissions}
                    priceToPay={priceToPay}
                    paymentLinksTotalToPay={paymentLinksTotalToPay}
                    savePriceToPay={setPriceToPay}
                    cardFee={cardFee}
                    getOrderData={getOrderData}
                  />
                </div>
              )
            )
          )}
        </div>
      </div>
    </>
  );
};

export default SplitPayment;
