import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_CARD, useCards } from "../../contexts/CardsContext";
import CardsAmount from "./CardsAmount/CardsAmount";
import PaymentSplit from "./PaymentSplit/PaymentSplit";
import { getUserDetails } from "../../services/authService";
import { payLds } from "../../services/fhpService";
import { Loader } from "../../Components/Loader/Loader";
import { useSelectedResult } from "../../contexts/SelectedResultContext";
import { round2 } from "../../contexts/AppContext";
import { message } from "antd";

const errorMessages = {
  // rebuild
  1: "כרטיס חסום",
  2: "גנוב החרם כרטיס",
  3: "התקשר לחברת האשראי",
  4: "העסקה לא אושרה",
  5: "כרטיס מזוייף החרם",
  6: "דחה עסקה: cvv2 שגוי",
  7: "דחה עסקה: cavv/ucaf שגוי",
  8: "דחה עסקה: avs שגוי",
  9: "דחייה - נתק בתקשורת",
  10: "אישור חלקי",
  11: "דחה עסקה: חוסר בנקודות/כוכבים/מיילים/הטבה אחרת",
  12: "בכרטיס לא מורשה במסוף",
  13: "דחה בקשה .קוד יתרה שגוי",
  14: "דחייה .כרטיס לא משוייך לרשת",
  15: "דחה עסקה: הכרטיס אינו בתוקף",
  16: "דחייה -אין הרשאה לסוג מטבע",
  17: "דחייה -אין הרשאה לסוג אשראי בעסקה",
  26: "דחה עסקה - idשגוי",
  41: "ישנה חובת יציאה לשאילתא בגין תקרה בלבד לעסקה עם פרמטר j2",
  42: "ישנה חובת יציאה לשאילתא לא רק בגין תקרה, לעסקה עם פרמטר j2",
  51: "חסר קובץ ווקטור 1",
  52: "חסר קובץ ווקטור 4",
  53: "חסר קובץ ווקטור 6",
  55: "חסר קובץ ווקטור 11",
  56: "חסר קובץ ווקטור 12",
  57: "חסר קובץ ווקטור 15",
  58: "חסר קובץ ווקטור 18",
  59: "חסר קובץ ווקטור 31",
  60: "חסר קובץ ווקטור 34",
  61: "חסר קובץ ווקטור 41",
  62: "חסר קובץ ווקטור 44",
  63: "חסר קובץ ווקטור 64",
  64: "חסר קובץ ווקטור 80",
  65: "חסר קובץ ווקטור 81",
  66: "חסר קובץ ווקטור 82",
  67: "חסר קובץ ווקטור 83",
  68: "חסר קובץ ווקטור 90",
  69: "חסר קובץ ווקטור 91",
  70: "חסר קובץ ווקטור 92",
  71: "חסר קובץ ווקטור 93",
  73: "חסר קובץ PARAM_3_1",
  74: "חסר קובץ PARAM_3_2",
  75: "חסר קובץ PARAM_3_3",
  76: "חסר קובץ PARAM_3_4",
  77: "חסר קובץ PARAM_361",
  78: "חסר קובץ PARAM_363",
  79: "חסר קובץ PARAM_364",
  80: "חסר קובץ PARAM_61",
  81: "חסר קובץ PARAM_62",
  82: "חסר קובץ PARAM_63",
  83: "חסר קובץ CEIL_41",
  84: "חסר קובץ CEIL_42",
  85: "חסר קובץ CEIL_43",
  86: "חסר קובץ CEIL_44",
  87: "חסר קובץ DATA",
  88: "חסר קובץ JENR",
  89: "חסר קובץ Start",
  101: "חסרה כניסה בוקטור 1",
  103: "חסרה כניסה בוקטור 4",
  104: "חסרה כניסה בוקטור 6",
  106: "חסרה כניסה בוקטור 11",
  107: "חסרה כניסה בוקטור 12",
  108: "חסרה כניסה בוקטור 15",
  110: "חסרה כניסה בוקטור 18",
  111: "חסרה כניסה בוקטור 31",
  112: "חסרה כניסה בוקטור 34",
  113: "חסרה כניסה בוקטור 41",
  114: "חסרה כניסה בוקטור 44",
  116: "חסרה כניסה בוקטור 64",
  117: "חסרה כניסה בוקטור 81",
  118: "חסרה כניסה בוקטור 82",
  119: "חסרה כניסה בוקטור 83",
  120: "חסרה כניסה בוקטור 90",
  121: "חסרה כניסה בוקטור 91",
  122: "חסרה כניסה בוקטור 92",
  123: "חסרה כניסה בוקטור 93",
  141: "חסרה כניסה מתאימה בקובץ פרמטרים 3.2",
  142: "חסרה כניסה מתאימה בקובץ פרמטרים 3.3",
  143: "חסרה כניסה בקובץ תחומי מועדון 3.6.1",
  144: "חסרה כניסה בקובץ תחומי מועדון 3.6.3",
  145: "חסרה כניסה בקובץ תחומי מועדון 3.6.4",
  146: "חסרה כניסה בקובץ תקרות לכרטיסי 4.1 PL",
  147: "חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PLשיטה 4.2 0",
  148: "חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PLשיטה 4.3 1",
  149: "חסרה כניסה בקובץ תקרות לכרטיסי תייר 4.4",
  150: "חסרה כניסה בקובץ כרטיסים תקפים -ישראכרט",
  151: "חסרה כניסה בקובץ כרטיסים תקפים -כאל",
  152: "חסרה כניסה בקובץ כרטיסים תקפים -מנפיק עתידי",
  182: "שגיאה בערכי וקטור 4",
  183: "שגיאה בערכי וקטור 6/12",
  186: "שגיאה בערכי וקטור 18",
  187: "שגיאה בערכי וקטור 34",
  188: "שגיאה בערכי וקטור 64",
  190: "שגיאה בערכי וקטור 90",
  191: "נתונים לא תקינים בוקטור הרשאות מנפיק",
  192: "נתונים לא ולידים בסט הפרמטרים",
  193: "נתונים לא ולידים בקובץ פרמטרים ברמת מסוף",
  300: "אין הרשאה לסוג עסקה - הרשאת סולק",
  301: "אין הרשאה למטבע - הרשאת סולק",
  303: "אין הרשאת סולק לביצוע עסקה כאשר הכרטיס לא נוכח",
  304: "אין הרשאה לאשראי - הרשאת סולק",
  308: "אין הרשאה להצמדה - הרשאת סולק",
  309: "אין הרשאת סולק לאשראי במועד קבוע",
  310: "אין הרשאה להקלדת מספר אישור מראש",
  311: "אין הרשאה לבצע עסקאות לקוד שרות 587",
  312: "אין הרשאת סולק לאשראי דחוי",
  313: "אין הרשאת סולק להטבות",
  314: "אין הרשאת סולק למבצעים",
  315: "אין הרשאת סולק לקוד מבצע ספציפי",
  316: "אין הרשאת סולק לעסקת טעינה",
  317: "אין הרשאת סולק לטעינה/פריקה בקוד אמצעי התשלום בשילוב קוד מטבע",
  318: "אין הרשאת סולק למטבע בסוג אשראי זה",
  319: "אין הרשאת סולק לטיפ",
  341: "אין הרשאה לעסקה - הרשאת מנפיק",
  342: "אין הרשאה למטבע - הרשאת מנפיק",
  343: "אין הרשאת מנפיק לביצוע עסקה כאשר הכרטיס לא נוכח",
  344: "אין הרשאה לאשראי - הרשאת מנפיק",
  348: `אין הרשאה לביצוע אישור בקשה יזומה ע"י קמעונאי`,
  349: "אין הרשאה מתאימה לביצוע בקשה לאישור ללא עסקה J5",
  350: "אין הרשאת מנפיק להטבות",
  351: "אין הרשאת מנפיק לאשראי דחוי",
  352: "אין הרשאת מנפיק לעסקת טעינה",
  353: "אין הרשאת מנפיק לטעינה/פריקה בקוד אמצעי התשלום",
  354: "אין הרשאת מנפיק למטבע בסוג אשראי זה",
  381: "אין הרשאה לבצע עסקת contactlessמעל סכום מרבי",
  382: "במסוף המוגדר כשרות עצמי ניתן לבצע רק עסקאות בשירות עצמי",
  384: "מסוף מוגדר כרב-ספק /מוטב - חסר מספר ספק/מוטב",
  385: "במסוף המוגדר כמסוף סחר אלקטרוני חובה להעביר eci",
  401: "מספר התשלומים גדול מערך שדה מספר תשלומים מקסימלי",
  402: "מספר התשלומים קטן מערך שדה מספר תשלומים מינימלי",
  403: "סכום העסקה קטן מערך שדה סכום מינמלי לתשלום !!!",
  404: "לא הוזן שדה מספר תשלומים",
  405: "חסר נתון סכום תשלום ראשון /קבוע",
  406: 'סה"כ סכום העסקה שונה מסכום תשלום ראשון +סכום תשלום קבוע *מספר תשלומים',
  408: "ערוץ 2 קצר מ-37 תווים",
  410: "דחיה מסיבת dcode",
  414: "בעסקה עם חיוב בתאריך קבוע הוכנס תאריך מאוחר משנה מבצוע העיסקה",
  415: "הוזנו נתונים לא תקינים",
  416: "תאריך תוקף לא במבנה תקין",
  417: "מספר מסוף אינו תקין",
  418: "חסרים פרמטרים חיוניים (להודעת שגיאה זו מתווספת רשימת הפרמטרים החסרים)",
  419: "שגיאה בהעברת מאפיין clientInputPan",
  420: "מספר כרטיס לא ולידי -במצב של הזנת ערוץ 2בעסקה ללא כרטיס נוכח",
  421: "שגיאה כללי -נתונים לא ולידים",
  422: "שגיאה בבנית מסר ISO",
  424: "שדה לא נומרי",
  425: "רשומה כפולה",
  426: "הסכום הוגדל לאחר ביצוע בדיקות אשראית",
  428: "חסר קוד שרות בכרטיס",
  429: "כרטיס אינו תקף לפי קובץ כרטיסים תקפים",
  431: "שגיאה כללית",
  432: "אין הראשה להעברת כרטיס דרך קורא מגנטי",
  433: "חיוב להעביר ב - PinPad",
  434: "אסור להעביר כרטיס במכשיר ה- PinPad",
  435: "המכשיר לא מוגדר להעברת כרטיס מגנטי CTL",
  436: "המכשיר לא מוגדר להעברת כרטיס EMV CTL",
  439: "אין הרשאה לסוג אשראי לפי סוג עסקה",
  440: "כרטיס תייר אינו מורשה לסוג אשראי זה",
  441: "אין הרשאה לביצוע סוג עסקה - כרטיס קיים בוקטור 80",
  442: "אין לבצע Stand-inלאימות אישור לסולק זה",
  443: "לא ניתן לבצע עסקת ביטול - כרטיס לא נמצא בקובץ תנועות הקיים במסוף",
  445: "בכרטיס חיוב מיידי ניתן לבצע אשראי חיוב מיידי בלבד",
  447: "מספר כרטיס שגוי",
  448: "חיוב להקליד כתובת לקוח (מיקוד ,מספר בית ועיר)",
  449: "חיוב להקליד מיקוד",
  450: 'קוד מבצע מחוץ לתחום, צ"ל בתחום 1-12',
  451: "שגיאה במהלך בנית רשומת עסקה",
  452: "בעסקת טעינה/פריקה/בירור יתרה חיוב להזין שדה קוד אמצעי תשלום",
  453: "אין אפשרות לבטל עסקת פריקה 7.9.3",
  455: "לא ניתן לבצע עסקת חיוב מאולצת כאשר נדרשת בקשה לאישור (למעט תקרות)",
  456: "כרטיס נמצא בקובץ תנועות עם קוד תשובה 'החרם כרטיס'",
  457: "בכרטיס חיוב מיידי מותרת עסקת חיוב רגילה/זיכוי/ביטול",
  458: "קוד מועדון לא בתחום",
  470: 'בעסקת הו"ק סכום התשלומים גבוה משדה סכום העסקה',
  471: 'בעסקת הו"ק מספר תשלום תורן גדול מסה"כ מספר התשלומים',
  472: "בעסקת חיוב עם מזומן חיוב להזין סכום במזומן",
  473: "בעסקת חיוב עם מזומן סכום המזומן צריך להיות קטן מסכום העסקה",
  474: "עסקת איתחול בהוראת קבע מחייבת פרמטר J5",
  475: 'עסקת ה"ק מחייבת אחד מהשדות: מספר תשלומים או סכום כולל',
  476: "עסקת תורן בהוראת קבע מחייבת שדה מספר תשלום",
  477: "עסקת תורן בהוראת קבע מחייבת מספר מזהה של עסקת איתחול",
  478: "עסקת תורן בהוראת קבע מחייבת מספר אישור של עסקת איתחול",
  479: "עסקת תורן בהוראת קבע מחייבת שדות תאריך וזמן עסקת איתחול",
  480: "חסר שדה מאשר עסקת מקור",
  481: "חסר שדה מספר יחידות כאשר העסקה מתבצעת בקוד אמצעי תשלום השונה ממטבע",
  482: "בכרטיס נטען מותרת עסקת חיוב רגילה/זיכוי/ביטול/פריקה/טעינה/בירור יתרה",
  483: "עסקה עם כרטיס דלק במסוף דלק חיוב להזין מספר רכב",
  484: 'מספר רכב המוקלד שונה ממספר הרכב הצרוב ע"ג הפס המגנטי/מספר בנק שונה מ-012/ספרות שמאליות של מספר הסניף שונה מ-44',
  485: 'מספר רכב קצר מ- 6ספרות /שונה ממספר הרכב המופיע ע"ג ערוץ 2 (פוזיציה 34 בערוץ 2) כרטיס מאפיין דלק של לאומי קארד',
  486: "ישנה חובת הקלדת קריאת מונה (פוזיציה 30בערוץ )2כרטיס מאפיין דלק של לאומי קארד",
  487: "רק במסוף המוגדר כדלק דו שלבי ניתן להשתמש בעדכון אובליגו",
  489: "בכרטיס דלקן מותרת עסקת חיוב רגילה בלבד (עסקת ביטול אסורה)",
  490: "בכרטיסי דלק/דלקן/דלק מועדון ניתן לבצע עסקאות רק במסופי דלק",
  491: "עסקה הכוללת המרה חייבת להכיל את כל השדות conversion_rate_06, conversion_rate_09, conversion_currency_51",
  492: "אין המרה על עסקאות שקל/דולר",
  493: "בעסקה הכוללת הטבה חיוב שיהיו רק אחד מהשדות הבאים: סכום הנחה/מספר יחידות/% ההנחה",
  494: "מספר מסוף שונה",
  495: "אין הרשאת fallback",
  496: "לא ניתן להצמיד אשראי השונה מאשראי קרדיט/תשלומים",
  497: "לא ניתן להצמיד לדולר/מדד במטבע השונה משקל",
  498: 'כרטיס ישראכרט מקומי הספרטור צ"ל בפוזיציה 18',
  500: 'העסקה הופסקה ע"י המשתמש',
  504: "חוסר התאמה בין שדה מקור נתוני הכרטיס לשדה מספר כרטיס",
  505: "ערך לא חוקי בשדה סוג עסקה",
  506: "ערך לא חוקי בשדה eci",
  507: "סכום העסקה בפועל גבוה מהסכום המאושר",
  509: "שגיאה במהלך כתיבה לקובץ תנועות",
  512: "לא ניתן להכניס אישור שהתקבל ממענה קולי לעסקה זו",
  551: "מסר תשובה אינו מתאים למסר הבקשה",
  552: "שגיאה בשדה 55",
  553: "התקבלה שגיאה מהטנדם",
  554: "במסר התשובה חסר שדה mcc_18",
  555: "במסר התשובה חסר שדה response_code_25",
  556: "במסר התשובה חסר שדה rrn_37",
  557: "במסר התשובה חסר שדה comp_retailer_num_42",
  558: "במסר התשובה חסר שדה auth_code_43",
  559: "במסר התשובה חסר שדה f39_response_39",
  560: "במסר התשובה חסר שדה authorization_no_38",
  561: "במסר התשובה חסר/ריק שדה additional_data_48.solek_auth_no",
  562: "במסר התשובה חסר אחד מהשדות conversion_amount_06, conversion_rate_09, conversion_currency_51",
  563: "ערך השדה אינו מתאים למספרי האישור שהתקבלו auth_code_43",
  564: "במסר התשובה חסר/ריק שדה additional_amunts54.cashback_amount",
  565: "אי-התאמה בין שדה 25לשדה 43",
  566: "במסוף המוגדר כתומך בדלק דו-שלבי יש חובה להחזיר שדות 90,119",
  567: "שדות 25,127לא תקינים במסר עידכון אובליגו במסוף המוגדר כדלק דו-שלבי",
  598: "ERROR_IN_NEG_FILE",
  599: "שגיאה כללית",
  700: 'עסקה נדחתה ע"י מכשיר PinPad',
  701: "שגיאה במכשיר pinpad",
  702: "יציאת com לא תקינה",
  703: "PINPAD_TransactionError",
  704: "PINPAD_TransactionCancelled",
  705: "PINPAD_UserCancelled",
  706: "PINPAD_UserTimeout",
  707: "PINPAD_UserCardRemoved",
  708: "PINPAD_UserRetriesExceeded",
  709: "PINPAD_PINPadTimeout",
  710: "PINPAD_PINPadCommsError",
  711: "PINPAD_PINPadMessageError",
  712: "PINPAD_PINPadNotInitialized",
  713: "PINPAD_PINPadCardReadError",
  714: "PINPAD_ReaderTimeout",
  715: "PINPAD_ReaderCommsError",
  716: "PINPAD_ReaderMessageError",
  717: "PINPAD_HostMessageError",
  718: "PINPAD_HostConfigError",
  719: "PINPAD_HostKeyError",
  720: "PINPAD_HostConnectError",
  721: "PINPAD_HostTransmitError",
  722: "PINPAD_HostReceiveError",
  723: "PINPAD_HostTimeout",
  724: "PINVerificationNotSupportedByCard",
  725: "PINVerificationFailed",
  726: "שגיאה בקליטת קובץ config.xml",
  730: "מכשיר אישר עסקה בניגוד להחלטת אשראית",
  731: "כרטיס לא הוכנס",
  777: "תקין, ניתן להמשיך",
};

const Payment = ({
  our_order_id,
  leftToPay,
  sumpaid,
  cardsAmount,
  setCardsAmount,
}) => {
  const { cards, updateCards } = useCards();

  const [paymentLinks, setPaymentLinks] = React.useState([]);
  const [paymentLinksTotalToPay, setPaymentLinksTotalToPay] = React.useState(0);

  const { t } = useTranslation();
  const { setOurSavedLDSOrder, ourPrice } = useSelectedResult();

  const handleCardsAmountChange = (newValue) => {
    // Check if the entered value is greater than or equal to 1
    if (!isNaN(newValue) && newValue >= 1) {
      let tempCards = [];
      if (cards.length > newValue) {
        if (paymentLinks.length > newValue)
          return message.error(
            "לא ניתן להפחית את מספר הכרטיסים מתחת לכמות הקישורים לתשלום אשר נוצרו"
          );
        tempCards = cards.slice(0, newValue);
      } else {
        if (paymentLinksTotalToPay < ourPrice.priceWithCreditCardFee) {
          tempCards = cards;
          for (let i = cards.length; i < newValue; i++) {
            tempCards.push({ ...EMPTY_CARD });
          }
        } else return message.warning("לא נשאר יותר כסף לתשלום");
      }
      // tempCards[0].amount = currentHotelOrder.ourPrice.priceWithCreditCardFee;

      updateCards(tempCards);
    } else {
      // If the entered value is less than 1 or not a valid number, set it to 1
      updateCards([EMPTY_CARD]);
    }
  };

  const renderTotalPays = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirections: "row",
          justifyContent: "space-around",
        }}
      >
        <h2
          className="text-header"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {t("packages.paid")}: ₪
          <span style={{ color: "#25ac03" }}>{sumpaid}</span>
        </h2>
        <h2
          className="text-header"
          style={{ display: "flex", flexDirection: "row" }}
        >
          יתרה: ₪
          <span style={{ color: "red" }}>
            {leftToPay ? leftToPay : <Loader />}
          </span>
        </h2>
      </div>
    );
  };

  return (
    <>
      <div
        className="lg:tw-w-[700px]"
        // style={{ width: 700 }}
      >
        {renderTotalPays()}

        <div style={{ padding: "10px 20px", border: "1px solid black" }}>
          <CardsAmount
            amount={cardsAmount}
            setAmount={handleCardsAmountChange}
          />
        </div>
        <div style={{ padding: 20, border: "1px solid black" }}>
          <PaymentSplit
            handlingFee={1}
            commission={1}
            priceToPay={leftToPay}
            setPriceToPay={console.log}
            setCardPaid={console.log}
            paymentLinks={paymentLinks}
            paymentLinksTotalToPay={paymentLinksTotalToPay}
            setPaymentLinks={setPaymentLinks}
            setPaymentLinksTotalToPay={setPaymentLinksTotalToPay}
          />
        </div>
      </div>
    </>
    // </CardsProvider>
  );
};

export default Payment;
