import React from "react";
import "./OrdersFilters.css";
import OrderFiltersTabs from "./orderFiltersTabs/OrderFitlersTabs";
import { useTranslation } from "react-i18next";
// import FlightsFilters from "./Filters/FlightsFilters";
import HotelsFilters from "./Filters/HotelsFilters/HotelsFilters";
import dayjs from "dayjs";

const OrdersFilters = ({
  isLoading,
  orders,
  setFilteredOrders,
  currentTabIndex,
  setCurrentTabIndex,
}) => {
  const { t } = useTranslation();
  const tabs = [
    { title: t("orders.tabs.flights"), component: <div>בבנייה</div> },
    {
      title: t("orders.tabs.hotels"),
      component: (
        <HotelsFilters
          orders={orders}
          filterOrders={(filters) => setFilteredOrders(filterData(filters))}
        />
      ),
    },
    {
      title: t("orders.tabs.hotels_and_flights"),
      component: <div>בבנייה</div>,
    },
    { title: t("orders.tabs.packages"), component: <div>בבנייה</div> },
  ];

  const filterData = (filters) => {
    function parseDDMMYYYY(dateStr) {
      const parts = dateStr.split("/");
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-based
      const year = parseInt(parts[2], 10);

      if (!day || !month || !year) {
        return null; // or throw an error, depending on how you want to handle invalid input
      }

      return new Date(year, month, day);
    }

    return orders.filter((item) => {
      // Destructure filters for easy access
      const {
        order_dates,
        staying_dates,
        hotel_name,
        main_contact,
        order_id,
        refundableuntil,
        notPaid,
      } = filters;

      // Check order date (creationdate in your data)
      const orderDateMatch = order_dates.start
        ? parseDDMMYYYY(item.creationdate) >=
            parseDDMMYYYY(order_dates.start) &&
          parseDDMMYYYY(item.creationdate) <= parseDDMMYYYY(order_dates.end)
        : true;

      // Check staying dates (checkin and checkout in your data)
      const stayingDateMatch = staying_dates.start
        ? parseDDMMYYYY(item.checkin) >= parseDDMMYYYY(staying_dates.start) &&
          parseDDMMYYYY(item.checkout) <= parseDDMMYYYY(staying_dates.end)
        : true;

      // Check hotel name
      const hotelNameMatch = hotel_name
        ? item.hotelname.toLowerCase().includes(hotel_name.toLowerCase())
        : true;

      // Check main contact (fullname in your data)
      const mainContactMatch = main_contact
        ? item.fullname.toLowerCase().includes(main_contact.toLowerCase())
        : true;

      // Check order ID (orderid in your data)
      const orderIdMatch = order_id
        ? item.orderid.toString().includes(order_id.toString()) ||
          item.id.toString().includes(order_id.toString())
        : true;

      // Check cancel until date
      const cancelUntilMatch = refundableuntil
        ? dayjs(item.refundableuntil, "DD/MM/YYYY").toDate() <= refundableuntil
        : true;

      const notPaidMatch = notPaid ? !item.paid : true;

      // Return the item if all conditions are true
      return (
        orderDateMatch &&
        stayingDateMatch &&
        hotelNameMatch &&
        mainContactMatch &&
        orderIdMatch &&
        cancelUntilMatch &&
        notPaidMatch
      );
    });
  };

  const handleChange = (newValue) => {
    setCurrentTabIndex(newValue);
  };

  return (
    <div className="orders-filters">
      <OrderFiltersTabs
        handleChange={handleChange}
        tabIndex={currentTabIndex}
        tabs={tabs}
        disabled={isLoading}
      />
      <div
        className="tw-bg-white tw-mt-[15px] tw-mb-[15px] tw-shadow-[0px_2px_4px_#cbc8c8] tw-rounded-[10px]"
        style={{
          padding: "20px",
        }}
      >
        {tabs[currentTabIndex].component}
      </div>
    </div>
  );
};

export default OrdersFilters;
